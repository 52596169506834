.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .login-form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .wrapper {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
  
  .login {
    border-radius: var(--regular-border-radius);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    overflow: hidden;
    width: 480px;
    max-width: 100%;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid var(--light-gray);
  }
  .header > img {
    height: 50px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 0.5;
  }
  .title{
    font-size: 14px;
    margin-top: 30px;
  }
    
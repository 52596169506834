.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 20px;
    justify-content: center;
    align-items: center;
  }
  .inputs_wrapper{
    display: flex;
    flex-direction: row;
    gap: 15px
  }
  .create-institution {
    width: 100%;
  }
  .inputs_left{
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  .inputs {
    display: flex;
    gap: 20px;
  }
.inputs_right{
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    gap: 10px;
    width: 40%;
}
.inputs_right p{
    color: black;
    font-size: 1.5rem;
}
.check_level{
  margin-bottom: 5px;
}
.expand{
  display: flex;
  cursor: pointer;
  align-items: center;
}
.inst{
  max-height: 320px;
  overflow-y: scroll;
}
.inst::-webkit-scrollbar {
display: none;
}
.search-bar {
  display: flex;
  width: 90%;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.filter_text {
  width: 55%;
  height: 24px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
}
.inputsImg{
  display: flex;
  gap: 20px;
  margin-top: 50px;

}
@media (max-width: 950px){
  .inputs_wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .inputs{
    display: flex;
    flex-direction: column;
  }
  .inputsImg{
    display: flex;
    flex-direction: column;
  }
  .inputs_right{
    width: 100%;
  }
  .inputs_left{
    width: 100%;
  }
}
@media (max-width: 650px){
  .inputsImg{
    margin-top: 20px;
  }
}
 .announcement {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--main-white);
    padding: 5px 18px;
    border-radius: var(--regular-border-radius);
    border-left: 7px solid var(--ter-blue);
    min-height: 60px;
    align-items: center;
  }
  .header{
    width: 100%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
 .announcement h2 {
      font-weight: 400;
      font-size: 1.5rem;
      width: 50%;
    }
 .announcement .more {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      font-size: 1.5rem;
      color: var(--main-gray);
      cursor: pointer;
      width: 50%;
    }
    
.header:hover .more {
  scale: 1.1;
  margin-right: 2rem;
}
.announcement .more p {
    padding-right: 10px;
}
.announcement .more i {
    font-size: 2.5rem;
}
.announcement_header{
    display: flex;
    width: 100%;
}
.content{
    border-top: 1px solid var(--main-gray);
    padding-top: 1rem;
    width: 100%;
    font-size: 14px;
}
.date{
  width: 100%;
  text-align: end;
  color: var(--main-gray)
}
.button_accept{
  padding: 1rem;
  background-color: var(--main-blue);
  border: none;
  color: var(--main-white);
  border-radius: var(--regular-border-radius);
  cursor: pointer;
}
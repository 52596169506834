.form__fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: var(--main-white);
  border-radius: var(--regular-border-radius);
  padding: 20px;
  justify-content: center;
  align-items: center;
}
.create-institution {
  width: 100%;
}
.inputs {
  display: flex;
  gap: 20px;
  margin-top: 1rem;
}
.inputs_solo{
  display: flex;
  justify-content: flex-start;
  width: 480px;
}
.add_levels{
  width: 720px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}
.add_levels p{
  cursor: pointer;
}
input{
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid var(--main-gray);
  height: 35.5px;
}
select{
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid var(--main-gray);
}
@media (max-width: 950px){
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

}
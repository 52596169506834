sv-brand-info {}

.sv-brand-info {
  width: 100%;
  font-family: var(--font-family, $font-family);
  text-align: center;
  color: #161616;
  background: white;
  padding: 32px 0;
  box-shadow: 0px -1px 0px #D6D6D6;

  a {
    color: #161616;
    text-decoration-line: underline;
  }
}

.sv-brand-info__text {
  font-weight: 600;
  font-size: calcFontSize(1);
  line-height: calcSize(3);
  color: #161616;
}

.sv-brand-info__logo {
  img {
    width: 118px;
  }

  display: inline-block;
}

.sv-brand-info__terms {
  font-weight: 400;
  font-size: calcFontSize(0.75);
  line-height: calcSize(2);

  a {
    color: #909090;
  }
}
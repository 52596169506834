.container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .form{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .form__fields{
    width: 65%;
    gap: 15px;
  }
  .wrapper {
    width: 100%;
    padding: 0 30px 5px 30px;
    background-color: var(--main-white);
    border-radius: 5px;
    height: 210px;
  }
  .wrapper form {
    height: 170px;
    display: flex;
    margin: 20px 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    border: 2px dashed var(--main-blue);
    cursor: pointer;
  }
  
  form :where(i, p) {
    color: var(--main-blue);
  }
  
  form i {
    font-size: 50px;
  }
  form p {
    font-size: 1.4rem;
    margin-top: 15px;
  }
  section .row {
    background: #e9f0ff;
    margin-bottom: 10px;
    list-style: none;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  section .row i {
    font-size: 3rem;
    color: var(--main-blue);
  }
  section .details span {
    font-size: 1.4rem;
  }
  .progressarea .row .content {
    width: 100%;
    margin-left: 15px;
  }
  .progressarea .details {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    justify-content: space-between;
  }
  .progressarea .progressbar {
    height: 6px;
    width: 100%;
    background: var(--main-white);
    margin-bottom: 4px;
    border-radius: 30px;
  }
  .progressbar .progress {
    height: 100%;
    width: 50%;
    background: var(--main-blue);
    border-radius: inherit;
  }
  section .details span {
    font-size: 1.4rem;
    align-items: center;
    justify-content: space-between;
  }
  .uploadedarea {
    margin-top: 20px;
  }
  .uploadedarea .row .content {
    display: flex;
    align-items: center;
  }
  .uploadedarea .row .details {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
  }
  .uploadedarea .details .size {
    font-size: 1.1rem;
    color: var(--main-gray);
  }
  .uploadedarea .fa-check {
    font-size: 1.6rem;
    color: var(--main-blue);
  }
  
.search-bar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.filter_text {
  width: 35%;
  height: 30px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
  margin: 1rem 2rem 0 0;
}
.data_table{
    margin: 2rem;
    height: 68vh;
    overflow-y: scroll;
    border-radius: var(--regular-border-radius);
}

.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 81vh;
    overflow-y: scroll;
}
.title{
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}
.options{
  display: flex;
  flex-direction: row;
  gap: 2rem;

}
.title2{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 15px;
}
.title2_selected{
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 15px;
  border-bottom: 2px solid var(--main-blue);
  cursor: pointer;
}
.task_info{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 20px 15px 20px;
    font-size: 14px;
}
.task{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 20px 15px 20px;
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-top: 10px;
}
.comment{
    font-size: 12px;
    background-color: var(--main-blue);
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
    cursor: pointer;
}
.submitted{
  font-size: 12px;
  background-color: var(--sec-green);
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  width: fit-content;

}
.review{
    font-size: 12px;
    border: 1px solid var(--main-blue);
    color: var(--main-blue);
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
    cursor: pointer;
}

.review:hover{
    font-size: 12px;
    border: 1px solid var(--main-blue);
    background-color: var(--main-blue);
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
    cursor: pointer;
}
.no_review{
    font-size: 12px;
    background-color: var(--sec-orange);
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;

}


.uploadedarea {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.row{
  display: flex;
  gap: 10px;
}
  .uploadedarea .row .content {
    display: flex;
    align-items: center;
  }
  .uploadedarea .row .details {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
  }
  .uploadedarea .details .size {
    font-size: 1.1rem;
    color: var(--main-gray);
  }
  .uploadedarea .details .name{
    font-size: 1.5rem;
  }
  .uploadedarea .fa-check {
    font-size: 1.6rem;
    color: var(--main-blue);
  }
  .form{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 10px;
  }
  .form__fields{
    width: 65%;
    gap: 15px;
  }
  .wrapper {
    width: 100%;
    padding: 0 30px 5px 30px;
    background-color: var(--main-white);
    border-radius: 5px;
  }
  .wrapper form {
    height: 80px;
    display: flex;
    margin: 20px 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    border: 2px dashed var(--main-blue);
    cursor: pointer;
  }
  
  form :where(i, p) {
    color: var(--main-blue);
  }
  
  form i {
    font-size: 35px;
  }
  form p {
    font-size: 1.4rem;
    margin-top: 5px;
  }
.quiz{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}
.download {
  margin-bottom: 10px;
  border: 1px solid var(--main-blue);
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: var(--main-blue);
  color: #fff;
  width: fit-content;
}
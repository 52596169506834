.new-chat {
  display: flex;
  justify-content: center;
  width: 100%;
  /* padding: 1rem; */
}
.filter_text {
  width: 100%;
  height: 30px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
  margin-bottom: 5px;
}

.new-chat i {
  color: var(--main-blue);
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}

.new-chat button:hover {
  background: var(--main-blue);
}

.user-list {
  height: 40vh;
  margin-bottom: 1rem;
  overflow-y: scroll;
}

.user {
  padding: 1rem;
  border: 1px solid #f0f0f0;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.user:hover {
  background-color: #f0f0f0;
}

.title {
  margin: 0;
}

.content {
  flex-direction: row;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.container {
  width: 100%;
}
.options {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  align-items: center;
  margin-bottom: 2rem;
}
.add {
  border: 1px solid var(--ter-blue);
  padding: 0.7rem 2rem 0.7rem 2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
}
.add:hover {
  box-shadow: 0 0 7px -1px var(--main-gray);
}
.option {
  min-width: 80px;
  max-width: 105px;
  display: flex;
  justify-content: center;
  background-color: var(--background-gray);
  padding: 1.2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
}
.option:hover {
  box-shadow: 0 0 7px -1px var(--main-gray);
}
.options p {
  font-size: 13px;
}

.option2 {
  min-width: 80px;
  max-width: 105px;
  display: flex;
  justify-content: center;
  background-color: var(--background-gray);
  padding: 1.2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
  box-shadow: 0 0 7px -1px var(--main-gray);
}
.form__fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: var(--main-white);
  border-radius: var(--regular-border-radius);
  padding: 20px;
}
.section_content {
  width: 100%;
}
.add_but {
  width: 170px;
  background-color: var(--main-white);
  color: black;
  margin-top: 2rem;
  border-radius: var(--regular-border-radius);
  padding: 1rem;
  border: 1px solid var(--main-blue);
  margin-bottom: 1rem;
  cursor: pointer;
}
.add_but:hover {
  width: 170px;
  background-color: var(--ter-blue);
  color: white;
  border: 1px solid var(--ter-blue);
}
.inputs {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}
.add_day {
  margin-bottom: 20px;
}
.table {
  border-radius: var(--regular-border-radius);
}
.inputs_section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.input_section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
}
.sections {
  margin: 20px;
}

.dropdown {
  /* display: block; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.dropdown label {
  width: 100%;
}

.dropdown select {
  margin: 5px 0;
  height: 41px;
  width: 150px;
  font-size: 1.5rem;
  border-radius: var(--regular-border-radius);
  border: 1px solid var(--main-gray);
}

@media screen and (max-width: 1318px) {
  .inputs_section {
    flex-direction: column;
    display: flex;
  }
}

.container{
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--background-gray);
    padding: 10px;
    border-radius: 10px;
}
.title{
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
}
.left_side{
    width: 20%;
    background-color: #fff;
    border-radius: var(--regular-border-radius);
    padding: 1rem;
}
.levels{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 78vh;
    overflow-y: scroll;
    padding: 3px;
}
.option {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--background-gray);
    padding: 1.2rem;
    border-radius: var(--regular-border-radius);
    cursor: pointer;
    font-size: 13px;
}
.option:hover {
  box-shadow: 0 0 7px -1px var(--main-gray);
}
  
.option2 {
    width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--background-gray);
  padding: 1.2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
  box-shadow: 0 0 7px -1px var(--main-gray);
  font-size: 13px;
}
.right_side{
    width: 80%;
    margin-left: 1rem;
    background-color: #fff;
    border-radius: var(--regular-border-radius);
    padding: 2rem;
}
@media (max-width: 950px) {
    .container {
      flex-direction: column;
      background-color:#fff;
        padding: 0px;
        border-radius: 0px;
    }
    .left_side {
      width: 100%;
      margin-bottom: 1rem;
    }
  
    .right_side {
      width: 100%;
      margin-left: 0;
      padding: 5px;
    }
  
    .option2 {
        width: 100%;
      display: flex;
      justify-content: center;
      background-color: var(--background-gray);
      padding: 1rem;
      border-radius: var(--regular-border-radius);
      cursor: pointer;
      box-shadow: 0 0 7px -1px var(--main-gray);
      font-size: 1.2rem;
      text-align: center;
    }
    .option {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: var(--background-gray);
        padding: 1rem;
        border-radius: var(--regular-border-radius);
        cursor: pointer;
        font-size: 1.2rem;
        text-align: center;
    }
  }
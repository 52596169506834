.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.formContent{
  display: flex;
  width: 100%;
}
.left_side {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.left_container {
  background-color: #fff;
  padding: 15px 10px 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.left_zoom {
  background-color: #fff;
  padding: 15px 10px 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.left_alert {
  background-color: var(--sec-purple);
  padding: 15px 10px 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
}
.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 5px;
}
.title3 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 5px;
}
.header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--main-blue);
  padding-bottom: 5px;
  padding-left: 5px;
}
.text {
  font-size: 1.5rem;
  margin-bottom: 5px;
}
.text3 {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: var(--main-blue);
  cursor: pointer;
}
.tex2 {
  font-size: 1.3rem;
  margin-bottom: 5px;
}

.left_content {
  margin-top: 10px;
  margin-left: 5px;
}
.right_side {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  max-height: 80vh;
  overflow-y: scroll;
}
.answer_true {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.answer_false {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.aswer_alert {
  font-size: 12px;
  background-color: var(--sec-red);
  color: #fff;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  width: fit-content;
  margin-left: 5px;
  height: fit-content;
}
.right_content {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.comment_content {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 100%;
}
.feedback_content {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 100%;
}
.accept_feedback {
  font-size: 12px;
  background-color: var(--sec-green);
  color: #fff;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  width: fit-content;
  margin-left: 5px;
  height: fit-content;
  cursor: pointer;
}
.edit_feedback {
  font-size: 12px;
  background-color: var(--main-gray);
  color: #fff;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  width: fit-content;
  margin-left: 5px;
  height: fit-content;
  margin-top: 5px;
  cursor: pointer;
}

.delete {
  border: 1px solid var(--sec-red);
  color: var(--sec-red);
  padding: 15px 10px 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.delete:hover {
  background-color: var(--sec-red);
  color: #ffffff;
}
@media (max-width: 690px){
  .formContent{
    flex-direction: column;
  }
  .left_side {
    width: 100%;
  }
  .right_side{
    width: 100%;
  }

}

:root {
  /* Main Color Pallete */
  --main-blue: #330072;
  --main-gray: #989a9e;
  --main-white: #ffffff;

  /* Secondary Color Pallete */
  --sec-purple: #685bc7;
  --sec-blue: #00a3e0;
  --sec-green: #43b02a;
  --sec-red: #d30019;
  --sec-orange: #f06400;
  --sec-yellow: #fed200;

  /* Tertiary Color Pallete */

  --ter-blue: #685bc7;
  --ter-green: #87be87;
  --ter-red: #ee3c3a;
  --ter-orange: #fcb514;
  --ter-yellow: #fbd546;

  --light-gray: #e4e4e4;
  --background-gray: #f5f5f5;

  /* Border radius */

  --regular-border-radius: 10px;
}

.search-bar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.filter_text {
  width: 35%;
  height: 30px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
  margin: 1rem 2rem 0 0;
}
.data_table{
    margin: 2rem;
    height: 68vh;
    overflow-y: scroll;
    border-radius: var(--regular-border-radius);
}
.add{
  border: 1px solid var(--ter-blue);
  padding: 0.7rem 2rem 0.7rem 2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
  width: 60px;
  margin: 1rem 2rem 0 2rem;
}
.add:hover{
  box-shadow: 0 0 7px -1px var(--main-gray);
}
.levels_header{
  display: flex;
}
.inputs {
  display: flex;
  gap: 20px;
  padding-bottom: 10px;
}
.rightside {
  width: 20%;
  background-color: var(--background-gray);
  border-radius: var(--regular-border-radius);
  padding: 10px 15px;
  margin: 0 0 0 10px;
  position: sticky;
}
.rightside::-webkit-scrollbar {
  display: none;
}
.reminders {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  height: 77vh;
  overflow-y: scroll;
}
.left {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.left .title {
  font-size: 14px;
  font-weight: bold;
}
.card_reminder {
  background: #fff;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 1rem 1rem 1rem 2rem;
  border-radius: var(--regular-border-radius);
  align-items: center;
}
@media (max-width: 950px){
  .rightside {
    width: 100%;
    background-color: var(--background-gray);
    border-radius: var(--regular-border-radius);
    padding: 10px 20px;
    margin: 20px 0 0 0;
    position: sticky;
  }
  .reminders {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    height: fit-content;
    overflow-y: scroll;
    padding-bottom: 15px;
  }
}





.inputs {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}
.inputs_button{
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
}
.add_schedule {
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.schedule_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.schedule_content {
  background-color: #fff;
  padding: 1rem;
  width: 100%;
  display: flex;
  gap: 5px;
  border-radius: var(--regular-border-radius);
  align-items: center;
}

.empty {
  font-size: 1.5rem;
  text-align: center;
}

.input-container {
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 100%;
  border: 1px solid var(--main-gray);
}

@media (max-width: 950px){
  .inputs {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    flex-direction: column;
  }
  .input-container {
    border-radius: var(--regular-border-radius);
    font-size: 1.5rem;
    padding: 8px;
    width: 100%;
    border: 1px solid var(--main-gray);
  }
}

.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 20px;
    justify-content: center;
    align-items: center;
  }
  .create-course {
    width: 100%;
  }
  .inputs {
    display: flex;
    flex-direction: column;
  }
  .inputs_left{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .form_rows{
    display: flex;
    flex-direction: row;
    
  }
.inputs_right{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    padding-left: 2rem;
}
.inputs_right p{
    color: black;
    font-size: 1.5rem;
}
.levels{
    max-height: 340px;
    overflow-y: scroll;
}
.levels::-webkit-scrollbar {
  display: none;
}
.check_level{
  margin-top: 1rem;
}
.search-bar {
    display: flex;
    width: 90%;
    justify-content: flex-start;
    margin-bottom: 1rem;
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .filter_text {
    width: 55%;
    height: 24px;
    border-radius: var(--regular-border-radius);
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
  }
  @media (max-width: 950px){
    .form_rows{
      flex-direction: column;
    }
  }
.basic-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  margin: 10px 20px 20px 20px;
  background-color: var(--main-white);
  border-radius: var(--regular-border-radius);
}

.info-item {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.info-item__label {
  font-size: 1.5rem;
  color: var(--main-blue);
  font-weight: 600;
}

.info-item__value {
  font-size: 1.25rem;
}

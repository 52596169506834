/* Align options to the beginning for radiogroup and checkbox */
.sv_main .sv_p_root .sv_q_checkbox_label, .sv_main .sv_p_root .sv_q_radiogroup_label {
    display: flex;
    flex-direction: row;
}

/*.sv_main .sv_p_root fieldset.sv_qcbc {
    line-height: var(--base-line-height, 2em);
    padding-top: 0.1em;
    display: flex;
}*/

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.databank {
  display: flex;
  gap: 10px;
  width: 100%;
  max-height: 88vh;
}

.entries {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 30%;
  height: 100%;
}
.top {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mid {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  height: 72vh;
  overflow-y: scroll;
  padding: 5px;
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 5px;
}

.search-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 5px;
}

.search-bar i {
  position: relative;
  font-size: 2rem;
  color: var(--main-gray);
  left: 10px;
}

.filter_text {
  width: 100%;
  height: 50px;
  font-size: 1.6rem;
  margin-left: -30px;
  padding-left: 40px;
  border-radius: var(--regular-border-radius);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid var(--background-gray);
}

.cta {
  display: flex;
  justify-content: center;
}

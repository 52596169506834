.full_container{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 74vh;
  width: 100%;
}

.container{
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(560px, 1fr));
  padding: 5px 2px 5px 5px;
  margin: 0 auto;
}

.content{
  width: 100%;
}

.question_answer{
  background-color: var(--main-white);
  padding: 20px 10px 20px 10px;
  border-radius: var(--regular-border-radius);
  width: 550px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: center;
  font-size: 16px;
  height: 280px;
}

.question_answer_text{
  background-color: var(--main-white);
  padding: 20px;
  border-radius: var(--regular-border-radius);
  width: 550px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 16px;
  height: 280px;
}

.question_answer_text p{
  text-align: center;
}

.answers_component{
  width: 100%;
  gap: 2rem;
  max-height: 230px ;
  overflow-y: scroll;
  margin-top: 5px;
}
.download {
  margin-bottom: 10px;
  border: 1px solid var(--main-blue);
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: var(--main-blue);
  color: #fff;
  width: fit-content;
}

.answer_content{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: var(--background-gray);
  padding: 1rem 2rem 1rem 2rem;
  margin: 1rem 0;
  border-radius: var(--regular-border-radius);
  flex-direction: column;
}

.answer_content .answer{
  margin-right: auto;
  text-align: start;
}

.answer_content .teacher{
  text-align: end;
  margin-left: auto;
  font-size: 13px;
  font-style: italic;
  color: var(--main-gray);
}

.table_container{
  border-radius: var(--regular-border-radius);
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.table_title{
  font-size: 18px; 
  font-weight: bold; 
  margin-top: 1rem; 
  margin-bottom: 1rem;
}
.backdrop {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

.modal {
  position: absolute;
  border-radius: 12px;
  box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
  background: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  min-width: 300px;
  max-height: 95vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: var(--main-blue);
  text-align: center;
  padding: 10px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: white;
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.header_title {
  width: 100%;
}
.header_icon {
  display: flex;
  justify-content: flex-end;
  width: 2%;
  cursor: pointer;
}

.modal-content {
  display: flex;
  flex-direction: column;
  /* padding: 15px; */
  font-size: 1.5rem;
  /* max-height: 85vh; */
  justify-content: center;
  overflow-y: scroll;
}

.modal-action {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-action > div {
  background-color: var(--main-blue);
  border-radius: var(--regular-border-radius);
  color: var(--main-white);
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-action > div:hover {
  background-color: var(--sec-purple);
  scale: 1.1;
}
@media (max-width: 950px){
  .modal-content {
    width: 100%;
    max-height: 80vh;
  }
}
@media (max-width: 400px){
  .modal-content {
    width: 100%;
    max-height: 75vh;
  }
}
.form {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
}

.form__fields {
  width: 100%;
  gap: 15px;
}
.row {
  display: flex;
  gap: 10px;
  align-items: center;
}
.wrapper {
  width: 100%;
  padding: 0 20px 5px 20px;
  background-color: var(--main-white);
  border-radius: 5px;
}
.wrapper form {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px dashed var(--main-blue);
  cursor: pointer;
  width: 550px;
}

form :where(i, p) {
  color: var(--main-blue);
}

form i {
  font-size: 35px;
}
form p {
  font-size: 1.4rem;
  margin-top: 5px;
}
@media (max-width: 850px) {
  .uploadedarea {
    width: 200px;
  }
  .wrapper form {
    width: 250px;
  }
}

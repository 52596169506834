.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

.leftside {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.image {
  width: 55px;
  display: flex;
  margin: 10px 0;
}
.image img {
  width: 45px;
  border-radius: 50%;
}
@media (max-width: 950px){
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
  }
}


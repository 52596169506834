.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    overflow-y: scroll;
}

.program_info{
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    width: 100%;
    flex-direction: column;
}

.right_side{
    background-color: var(--main-white);
    padding: 2rem ;
    border-radius: var(--regular-border-radius);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.left_side{
    background-color: var(--main-white);
    padding: 1rem 2rem 1rem 2rem ;
    border-radius: var(--regular-border-radius);  
    width: 100%;
}

.program_info .title{
    font-size: 24px;
    font-weight: bold;
}

.program_info .content{
    font-size: 16px;
    margin-top: 1rem;
}

.header_content{
    width: 100%;
}
.edit{
    width: 100%;
    display: flex;
}
.header_text{
    font-size: 13px;
    margin-top: 5px;
}
.content_link{
    text-decoration: none;
    color: var(--main-blue);
    cursor: pointer;
}
.filters{
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
}
.search-bar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
  
.filter_text {
    width: 30%;
    height: 30px;
    border-radius: 10px;
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
}
.filters select{
    width: 30%;
    height: 30px;
    border-radius: 10px;
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
}
.headers_options{
    display: flex;
    flex-direction: row;
}

.option {
    cursor: pointer;
    font-size: 14px;
    padding: 1rem 1rem 0.5rem 1rem;
  }
  .option_selected {
    padding: 1rem 1rem 0.5rem 1rem;
    border-bottom: 2px solid var(--main-blue);
    cursor: pointer;
    font-size: 14px;
  }
  @media (max-width: 950px){
    .program_info{
        flex-direction: column;
    }
    .right_side{
        width: 100%;
    }
    .left_side{
        width: 100%;
    }
    .filter_text{
        width: 50%;
        margin-top: 5px;
    }
  }
.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    max-height: 80vh;
}
.table_content{
  overflow-y: scroll;

}

  .content > div {
    width: 100%;
  }
  
  .search-bar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  
  .filter_text {
    width: 20%;
    height: 30px;
    border-radius: var(--regular-border-radius);
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
  }
  .image {
    width: 35px;
    display: flex;
    margin: 10px 0;
  }
  .image img {
    width: 30px;
    border-radius: 50%;
  }
  @media (max-width: 950px){
    .filter_text{
      width: 50%;
    }
  }
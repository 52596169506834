.sections {
  display: flex;
  gap: 10px;
  background-color: var(--background-gray);
  border-radius: var(--regular-border-radius);
  padding: 1rem;
}

.input_section {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}
.add {
  border: 1px solid var(--ter-blue);
  padding: 0.7rem 2rem 0.7rem 2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
  background-color: #fff;
}
.add:hover {
  box-shadow: 0 0 7px -1px var(--main-gray);
}
.add_teacher {
  border: 1px solid var(--ter-blue);
  border-radius: var(--regular-border-radius);
  cursor: pointer;
  background-color: var(--main-blue);
  height: 39px;
  display: flex;
  align-items: center;
  width: 180px;
  text-align: center;
}
.add_teacher p {
  color: #fff;
  width: 100%;
  padding: 0.7rem 2rem 0.7rem 2rem;
}
.add_teacher:hover {
  box-shadow: 0 0 10px -1px var(--main-gray);
  background-color: #fff;
  color: var(--main-blue);
}
.add_teacher p:hover {
  color: var(--main-blue);
}
.options {
  display: flex;
  gap: 1rem;

  align-items: center;
  margin-bottom: 10px;
}

.option {
  min-width: 80px;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  background-color: var(--background-gray);
  padding: 1.2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
}
.option:hover {
  box-shadow: 0 0 7px -1px var(--main-gray);
}
.options p {
  font-size: 13px;
}

.option2 {
  min-width: 80px;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  background-color: var(--background-gray);
  padding: 1.2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
  box-shadow: 0 0 7px -1px var(--main-gray);
}

.option3 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--background-gray);
  padding: 1.2rem;
  border-radius: var(--regular-border-radius);
}

.inputs {
  display: flex;
  gap: 1rem;
}
.sections_container{
  max-width: 100%;
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 1rem;
  
}
.courses_container{
  max-width: 100%;
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 1rem;

}
@media (max-width: 950px){
  .sections{
    padding: 5px;
  }
  .option {
    min-width: 50px;
    max-width: fit-content;
    display: flex;
    justify-content: center;
    background-color: var(--background-gray);
    padding: 1.2rem;
    border-radius: var(--regular-border-radius);
    cursor: pointer;
  }
  .option2 {
    min-width: 50px;
    max-width: fit-content;
    display: flex;
    justify-content: center;
    background-color: var(--background-gray);
    padding: 1.2rem;
    border-radius: var(--regular-border-radius);
    cursor: pointer;
    box-shadow: 0 0 7px -1px var(--main-gray);
  }

  .sections_container{
    max-width: 100%;
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 1rem;
    
  }
  .courses_container{
    max-width: 100%;
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1rem;

  }

}
.container{
    width: 100%;
    border-radius: var(--regular-border-radius);
}
.filter_text {
    width: 100%;
    height: 24px;
    border-radius: var(--regular-border-radius);
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
  }
  .search-bar2 {
    display: flex;
    width: 200px;
    justify-content: flex-start;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: auto;
  }
.wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.login {
  border-radius: var(--regular-border-radius);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  width: 480px;
  max-width: 100%;
  min-height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid var(--light-gray);
}
.header > img {
  height: 50px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
@media (max-width: 950px) {
  .login {
    width: 380px;
  }
}

.marketplace {
  font-size: 1.1rem;
  display: flex;
  color: var(--ter-blue);
  padding: 7px 5px;
  border: 1px solid var(--ter-blue);
  border-radius: var(--regular-border-radius);
  text-align: center;
  justify-content: center;
  font-weight: bold;
  max-width: 210px;
}

.marketplace:hover {
  background-color: var(--ter-blue);
  cursor: pointer;
  color: #fff;
}

label {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  text-align: center;
  gap: 10px;
}

input {
  padding: 5px;
  border: 0;
  font-size: 1.5rem;
}

.submit_buttom {
  display: flex;
  justify-content: center;
  /* margin-top: 5px; */
}

.submit_buttom button {
  /* margin: 20px 0; */
  width: 100%;
  font-size: 1.1rem;
  border-radius: var(--regular-border-radius);
}


.input-container {
  justify-content: space-between;
}

.login-input-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.input-container__input {
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 230px;
  /* margin-top: 5px;
  margin-bottom: 5px; */
  border: 1px solid var(--main-gray);
}
.input_container_email {
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 100%;
  border: 1px solid var(--main-gray);
}
.input_container_email_error {
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 100%;
  border: 1px solid var(--main-gray);
  border: 1px solid var(--sec-red);
}

.login-input-container__input {
  width: 200px;
  padding-left: 2rem;
  border-bottom: 1px solid var(--light-gray);
}

.input-container__input-error {
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 230px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid var(--sec-red);
}
.login-input-container__input:focus {
  outline: none;
  border-bottom: 1px solid var(--sec-purple);
}

.login-input-container > i {
  position: absolute;
  font-size: 1.5rem;
  color: var(--main-gray);
}

.error {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--sec-red);
  font-size: 1.1rem;
  width: 100%;
}
.checkbox_field {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  min-width: 230px;
}

.label {
  display: flex;
  gap: 20px;
}

.checkbox {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}
.select_input{
  margin-bottom: 3px;
}
.select_field {
  justify-content: space-between;
}
.select {
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 230px;
  border: 1px solid var(--main-gray);
}
.select-error {
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 230px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid var(--sec-red);
}
.msg_area {
  display: flex;
  flex-grow: 1;
  width: 100%;
}
.msg {
  border-radius: var(--regular-border-radius);
  border: 1px solid var(--main-gray);
  width: 100%;
  height: 37px;
  padding-left: 1rem;
}
.textarea {
  border-radius: var(--regular-border-radius);
  border: 1px solid var(--main-gray);
  padding: 1rem;
  overflow-y: scroll;
  resize: none;
  margin-top: 5px;
  width: 470px;
}

.textarea__input-error {
  border-radius: var(--regular-border-radius);
  border: 1px solid var(--sec-red);
  width: 470px;
  padding: 1rem;
  overflow-y: scroll;
  resize: none;
  margin-top: 5px;
}

.radio_field {
  display: flex;
  min-width: 100px;
  flex-direction: row;
  align-items: center;
}
.radio_input {
  display: flex;
  width: 100%;
}
.radio {
  margin-right: 1rem;
  width: 16px;
}

.msg-button:hover {
  background-color: var(--sec-purple);
}

.send-msg-button {
  border-radius: var(--regular-border-radius);
  background-color: var(--main-blue);
  border: none;
}

.send-msg-button:hover {
  background-color: var(--sec-purple);
}

.send-msg-button > i {
  font-size: 2rem;
  padding: 10px 15px;
  color: var(--main-white);
}

.form-button > i {
  color: var(--main-white);
  font-size: 1.5rem;
}
@media (max-width: 590px){
  .textarea__input-error {
    border-radius: var(--regular-border-radius);
    border: 1px solid var(--sec-red);
    width: 100%;
    padding: 1rem;
    overflow-y: scroll;
    resize: none;
    margin-top: 5px;
  }
  .textarea {
    width: 100%;
  }
  .input-container__input {
    width: 100%;
  }
  .checkbox_field {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
  }
  .input-container__input-error {
    width: 100%;
  }
  .select {
    border-radius: var(--regular-border-radius);
    font-size: 1.5rem;
    padding: 8px;
    width: 100%;
    border: 1px solid var(--main-gray);
  }
  .select-error {
    width: 100%;
  }
  .text_area{
    width: 100%;
  }
}

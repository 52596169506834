.input{
    border: 1px solid var(--main-gray);
    border-radius: 10px;
}
.button{
    background-color: var(--main-blue);
    color: #fff;
    border-radius: 10px;
    border: none;
    padding: 7px 20px;
    cursor: pointer;
}
.result{
    border-radius: 10px;
    border: 1px solid var(--main-gray);
    margin-top: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 400px;
}

.result:hover{
    background-color: var(--background-gray);
    cursor: pointer;
}


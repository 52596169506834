.table {
    width: 100%;
    border-radius: var(--regular-border-radius);
  }
  .alert {
    font-size: 12px;
    background-color: var(--sec-red);
    color: #fff;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
  }
  .search-bar {
    width: 230px;
    justify-content: flex-start;
    margin-left: auto;
  }
  .search-barthree {
    width: 200px;
    justify-content: flex-start;
    margin-left: 1rem;
  }
  .search-barfour {
    width: 200px;
    justify-content: flex-start;
    margin-left: 1rem;
  }
  .alert {
    font-size: 12px;
    background-color: var(--sec-red);
    color: #fff;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
  }
  .search-bar3 {
    width: 230px;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: auto;
  }
  .search-bartwo {
    width: 200px;
    justify-content: flex-start;
    margin-left: 1rem;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrar contenido horizontalmente */
  
}
.button{
  width: fit-content;
  background-color: var(--main-blue);
  color:#fff;
  border-radius: 10px;
  padding: 10px;
  border: none;
  margin-top: 10px;
  cursor: pointer;
}
.cont2 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: row;
}
  .filter_text {
    width: 100%;
    height: 28px;
    border-radius: var(--regular-border-radius);
    font-size: 1.3rem;
    border: 1px solid var(--main-gray);
  }
  
  .success {
    font-size: 12px;
    background-color: var(--sec-green);
    color: #ffffff;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
  }
  .info {
    font-size: 12px;
    background-color: var(--ter-orange);
    color: #ffffff;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
  }
  
  .download {
    margin-bottom: 10px;
    border: 1px solid var(--main-blue);
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    background-color: var(--main-blue);
    color: #fff;
    width: fit-content;
  }
  .questions_total {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    margin-top: 10px;
    border-radius: 10px;
  }
  .head_content {
    width: 100%;
    display: flex;
  }
  .cont {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .search-bar2 {
    display: flex;
    width: 200px;
    justify-content: flex-start;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
}

.text {
  font-size: 1.3rem;
  color: var(--main-blue);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  font-weight: bold;
}
.select {
  border-radius: var(--regular-border-radius);
  width: 100%;
}
.closed {
  color : var(--sec-red);
}
.completed {
  color : var(--sec-green);
}
.process{
  color : var(--sec-blue);
}
.pending {
  color : var(--sec-orange);
}
  @media (max-width: 850px) {
    .head_content {
      flex-direction: column;
    }
  }

  @media (min-width: 849px) {
    .autocomplete-listbox {
      width: '10hv';
  }
    
  }
  
.update-profile {
  margin: 10px 20px; 
}
.form__fields {
  background-color: var(--main-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px 20px 20px;
  border-radius: var(--regular-border-radius);
}
.inputs {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}
.profile-icon-edit {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}
.edit-button{
  border: 1px solid var(--main-blue);
  border-radius: 10px;
  padding: 7px 5px;
  background-color: transparent;
  color: var(--main-blue);
  cursor: pointer;
}
@media (max-width: 750px){
  .inputs {
    flex-direction: column;
  }
  
}

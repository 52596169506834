.submitted{
    font-size: 12px;
    background-color: var(--sec-green);
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
  
  }
  .task_info{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 20px 15px 20px;
    font-size: 12px;
}
.task{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 20px 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    margin-top: 10px;

}
.task_row{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
}
.review{
    font-size: 12px;
    border: 1px solid var(--main-blue);
    color: var(--main-blue);
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
    cursor: pointer;
}

.review:hover{
    font-size: 12px;
    border: 1px solid var(--main-blue);
    background-color: var(--main-blue);
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
    cursor: pointer;
}
.quiz{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
  }
.answerComp{
    display: flex;
    gap: 10px;
    justify-content: center;
}
@media (max-width: 750px){
    .answerComp{
        flex-direction: column;
    }
}
.App {
  display: flex;
}

.Content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
}
.Content .cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 15px;
}

@media (max-width: 950px){
  .Content .cont {
    margin: 10px;
    padding-right: 15px;
  }
}
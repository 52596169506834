.calendar_container{
    width: 100%;
    padding: 1rem;
}

:root {
    --fc-button-bg-color: var(--sec-purple); 
    --fc-button-border-color: var(--ter-blue);
    --fc-button-hover-bg-color: var(--main-blue);
    --fc-button-active-bg-color: var(--main-blue);
    --fc-button-active-border-color: var(--main-blue);

    --fc-event-bg-color: var(--sec-purple);
    --fc-event-border-color: var(--sec-purple);
}
.container-column {
  display: flex;
  flex-direction: column;
  background-color: var(--background-gray);
  border-radius: var(--regular-border-radius);
  overflow-x: hidden;
}

.container-row {
  display: flex;
  background-color: var(--background-gray);
  padding: 10px;
  width: 100%;
  border-radius: var(--regular-border-radius);
  overflow-x: hidden;
}
.container_inst {
  display: flex;
  background-color: var(--background-gray);
  padding: 20px;
  width: 100%;
  border-radius: var(--regular-border-radius);
  max-height: 87vh;
  overflow-x: hidden;
  
}
@media (max-width: 750px){
  .container_inst {
    padding: 5px;
    max-height: 100%;
  }
  .container-row {
    padding: 5px;
  }
}

.container {
  width: 100%;
  background-color: var(--background-gray);
  padding: 10px;
  border-radius: 10px;
}
.options {
  display: flex;
  gap: 1rem;

  align-items: center;
  margin-bottom: 10px;
}
.add {
  border: 1px solid var(--ter-blue);
  padding: 0.7rem 2rem 0.7rem 2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
}
.add:hover {
  box-shadow: 0 0 7px -1px var(--main-gray);
}
.option {
  min-width: 80px;
  max-width: 105px;
  display: flex;
  justify-content: center;
  background-color: var(--background-gray);
  padding: 1.2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
}
.option:hover {
  box-shadow: 0 0 7px -1px var(--main-gray);
}
.options p {
  font-size: 13px;
}

.option2 {
  min-width: 80px;
  max-width: 105px;
  display: flex;
  justify-content: center;
  background-color: var(--background-gray);
  padding: 1.2rem;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
  box-shadow: 0 0 7px -1px var(--main-gray);
}
.form__fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: var(--main-white);
  border-radius: var(--regular-border-radius);
  padding: 2rem;
  align-items: center;
}
.section_content {
  width: 100%;
}
.add_schedule{
    display: flex;
    width: 100%;
}

.section_info {
  display: flex;
  flex-direction: column;
}

.add_but {
  background-color: var(--main-white);
  color: var(--main-white);
  border-radius: var(--regular-border-radius);
  padding: 1rem;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: var(--main-blue);
  cursor: pointer;
  height: 100%;
  font-size: 1.6rem;
  width: fit-content;
}

.add_but button {
  height: 100%;
}

.add_but:hover {
  background-color: var(--sec-purple);
  color: white;
}
.inputs {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}
.add_day {
  margin-bottom: 20px;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}
.table {
  border-radius: var(--regular-border-radius);
}

.inputs_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}
.inputs_journal {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.schedule {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  flex-direction: column;
}

.calendar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  /* padding: 5px; */
  background-color: var(--main-white);
  border-radius: var(--regular-border-radius);
}

.schedule-title {
  font-size: 2rem;
}

.day {
  font-size: 1.4rem;
  background-color: var(--main-white);
  text-align: center;
}
.dates_options{
  display: flex;
  flex-direction: column;
  width: 120px;
}
.check_level{
  margin-bottom: 5px;
}
.day_text{
  color:#000;
  margin-top: 0;
  max-width: "400px";
  justify-content:  "flex-start";
  text-align:  "start";
  display: "flex";
  padding-left:  "10px";
}
.inputs_button{
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
}
.container2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.add_but2 {
  background-color: var(--main-white);
  color: var(--main-white);
  border-radius: var(--regular-border-radius);
  padding: 1rem;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: var(--main-blue);
  cursor: pointer;
  height: 100%;
  font-size: 1.6rem;
  width: fit-content;
}

/* .add_but2 button {
  height: 100%;
} */

.add_but2:hover {
  background-color: var(--sec-purple);
  color: white;
}
.container {
  display: flex;
  justify-content: space-between;
  /* gap: 10px; */
  margin-bottom: 10px;
  flex-direction: column;
}
.schedule-title2 {
  /* margin-left: 45%; */
  /* margin-bottom: 8px; */
  font-size: 1.5rem;
}
.schedule_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.schedule_content {
  background-color: #fff;
  padding: 1rem;
  width: 100%;
  display: flex;
  gap: 5px;
  border-radius: var(--regular-border-radius);
}
.schedule_content2 {
  background-color: #fff;
  padding: 1rem;
  width: 100%;
  display: flex;
  gap: 5px;
  border-radius: var(--regular-border-radius);
  margin-bottom: 10px;
}
@media (max-width: 750px){
  .inputs {
    flex-direction: column;
    width: 100%;
  }
  .inputs_journal{
    width: 100%;
  }
}
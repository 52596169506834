.register-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-white);
  border-radius: var(--regular-border-radius);
}
.form__fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.inputs {
  display: flex;
  gap: 20px;
  padding-top: 1rem;
}
.check_inputs {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.header {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.header h1 {
  margin-left: 10px;
}

.form_inputs{
  display: flex;
  flex-direction: column;
}
.form__fields_employee{
  display: flex;
  flex-direction: row;
  padding-bottom: 2rem;
}
.form_inputs_emp{
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
.inputs_teacher{
  margin-left: 2rem;
}
.add_inst{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.inst_button{
  width: 55px;
  height: 30px;
  font-size: 12px;
  padding: 0;
  margin-top: 0;
  cursor: pointer;
}

.inst{
  max-height: 280px;
  overflow-y: scroll;
}
.inst::-webkit-scrollbar {
display: none;
}
.check_level{
margin-top: 1rem;
}
.search-bar {
  display: flex;
  width: 90%;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.filter_text {
  width: 55%;
  height: 24px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
}
.inputs_right{
  margin-left: 2rem;
}
.inputs_right p{
  color: black;
  font-size: 1.5rem;
}
.expand{
  display: flex;
  cursor: pointer;
  align-items: center;
}
@media (max-width: 950px){
  .inputs{
    flex-direction: column;
  }
}
.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin-top: 10px;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .instructions-container {
    text-align: center;
  }
  
  .instructions-container a {
    font-size: 16px;
    color: var(--main-blue);
    padding: 8px;
    display: inline-block;
  }
  
  .color-aqui {
    color: var(--sec-orange);
  }
  .instructions-container a:hover {
    text-decoration: underline;
  }
  
  .reward-container {
    padding: 10px;
    text-align: center;
    font-weight: bold;
    color: var(--main-blue);
  }
  
  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  button {
    border-radius: 20px;
    padding: 8px 16px;
  }
  
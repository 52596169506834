.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 20px;
    justify-content: center;
    align-items: center;
}
.form_rows{
  display: flex;
  flex-direction: row;
  gap: 15px
}
.create-question{
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: 81vh;
}
.inputs {
    display: flex;
    flex-direction: row;
    gap: 2rem
}
.add_opt{
    display: 'flex';
    flex-direction: column;
}
.inputs_container{
    display: flex;
    flex-direction: row;
}
.right_side{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    width: 300px;
}
.left_side{
    display: flex;
    flex-direction: column;
}
.levels{
    max-height: 350px;
    overflow-y: scroll;
}
.levels::-webkit-scrollbar {
  display: none;
}
.search-bar {
    display: flex;
    width: 280px;
    justify-content: flex-start;
    margin-bottom: 1rem;
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    gap: 1rem
}
.filter_text {
    width: 50%;
    height: 24px;
    border-radius: var(--regular-border-radius);
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
}
.inputs_right{
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    gap: 10px;
}
.inputs_right p{
    color: black;
    font-size: 1.5rem;
}
.check_level{
  margin-bottom: 5px;
}
.inputs_left{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
@media (max-width: 950px){
  .form_rows{
      display: flex;
      flex-direction: column;
      gap: 15px
    }
}
.table {
    width: 100%;
    border-radius: var(--regular-border-radius);
}
.capacitaciones_video{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 5px;
}
.capacitacion_component{
  display: flex;
  flex-direction: column;
}
.download {
  margin-bottom: 10px;
  border: 1px solid var(--main-blue);
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: var(--main-blue);
  color: #fff;
  width: fit-content;
}
.filter_text {
  width: 100%;
  height: 24px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
}
.search-bar {
  display: flex;
  width: 200px;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: auto;
}
.search-bar2 {
  display: flex;
  width: 200px;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}
.head_content {
  width: 100%;
  display: flex;
}
.cont {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
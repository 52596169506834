.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 20px;
    justify-content: center;
    align-items: center;
}
.create-question{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    max-height: 81vh;
}
.inputs {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 100%;
}
.add_opt{
    display: flex;
    flex-direction: column;
}
.inputs_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.right_side{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}
.left_side{
    display: flex;
    flex-direction: column;
}
.radio{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    width: 470px;
    gap: 1rem;
}
@media (max-width: 950px){
    .inputs {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 1rem;
    }
    .create-question{
        max-height: fit-content;
    }
    .inputs_container{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .radio{
        flex-direction: column;
    }
    .form_rows{
        display: flex;
        flex-direction: column;
      }
}
@media (max-width: 650px){
    .textarea{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
  }
.slide-container {
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  margin: 0 auto;
  width: 80%; 
  max-width: 800px;  
  height: 100vh; 
  max-height: 100vh; 
  padding: 18px;
  box-sizing: border-box; 
  overflow: hidden; 
}

.slick-slider {
  align-items: center;
  justify-content: center;
  width: 100%; 
  max-width: 100%;
  height: 100vh; /* Ajusta según el tamaño disponible */
  max-height: 100%;
}

.slick-track {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px; /* Espaciado entre imágenes */
}
.image-container {
  display: flex; /* Flexbox para centrar */
  justify-content: center;
  margin-top: 10px;
  align-items: center;
  width: 100%; /* Se adapta al ancho del contenedor */
  height: auto; /* Mantén la proporción */
  border-radius: 10px;
}

.image-container img {
  max-width: 100%; /* Ajusta dinámicamente según el contenedor */
  height: auto; /* Mantén la proporción */
  max-height: 75vh; /* Limita la altura de la imagen */
  transition: opacity 1s ease-in-out, filter 1s ease-in-out;
  opacity: 0.5;
  filter: brightness(80%);
  display: block;
  margin: 0 auto;
  border-radius: 10px;
}

.slick-active img {
  opacity: 1;
  filter: brightness(100%);
}

.slick-dots {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
}

@media (max-width: 1200px) {
  .slide-container {
    width: 95%; 
    height: 75vh;
    max-height: 75vh;
  }

  .image-container img {
    max-height: 65vh; 
  }
}
@media (max-width: 800px) {
  .slide-container {
    width: 90%;
    height: 60vh;
    max-height: 60vh;
  }

  .image-container img {
    max-height: 55vh; 
  }
}

@media (max-width: 600px) {
  .slide-container {
    width: 95%; 
    height: 60vh;
    max-height: 60vh;
  }

  .image-container img {
    max-height: 52vh; 
  }
}

@media (max-width: 480px) {
  .slide-container {
    width: 95%;
    height: 40vh;
    max-height: 40vh;
  }

  .image-container img {
    max-height: 32vh; 
  }
}

.backdrop {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    z-index: 90;
}

.alert {
    position: absolute;
    border-radius: 12px;
    box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
    background: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    min-width: 300px;
    max-height: 95vh;
  }

.alert-content {
    display: flex;
    flex-direction: column;
    padding: 15px;
    font-size: 1.5rem;
    min-height: 100px;
    min-width: 300px;
    overflow-y: scroll;
    max-height: 85vh;
    align-items: center;
    justify-content: center;
}

.alert-action {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 1rem
}

.alert-action > div {
    background-color: var(--main-blue);
    border-radius: var(--regular-border-radius);
    color: var(--main-white);
    padding: 10px 20px;
    margin-bottom: 10px;
    font-size: 1.5rem;
    cursor: pointer;
}

.alert-action > div:hover {
  background-color: var(--sec-purple);
  scale: 1.1;
}
.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 20px;
    justify-content: center;
    align-items: center;
}
.search-bar {
    display: flex;
    width: 280px;
    justify-content: flex-start;
    margin-bottom: 1rem;
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    gap: 1rem;
}
.data_table{
    border-radius: var(--regular-border-radius);
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.search-bar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.filter_text {
    width: 20%;
    height: 30px;
    border-radius: var(--regular-border-radius);
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
}
.send_button{
    width: 90px;
    padding: 1rem;
    border-radius: var(--regular-border-radius);
    background-color: var(--main-blue);
    border: none;
    color: var(--main-white);
    margin-top: 2rem;
    cursor: pointer;
}
.inputs {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 1rem;
}
@media (max-width: 590px){
    .inputs {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 1rem;
        width: 100%;
    }
}
.body {
  min-height: 100vh;
  overflow-x: hidden;
}
.sidebar {
  font-family: "Poppins" sans-serif;
  font-size: 1.6rem;
  position: relative;
  width: 80px;
  transition: width 0.5s;
  margin-right: 3rem;
  inset: 10px;
}

.navigation {
  position: fixed;
  inset: 10px;
  width: 80px;
  background: var(--main-blue);
  border-left: 10px solid var(--main-blue);
  transition: 0.5s;
  overflow: hidden;
  border-radius: 20px;
}
.sidebar:hover {
  width: 220px;
  margin-right: 6rem;
  transition: width 0.5s;
}
.navigation:hover {
  width: 225px;
  transition: 0.5s;
}
.navigation .options {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 20px;
  height: 100%;
}
.navigation .options li {
  position: relative;
  width: 100%;
  list-style: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.navigation .options li:hover,
.navigation .options li.hovered {
  background: var(--main-white);
}
.navigation .options li:nth-child(1) {
  margin-bottom: 40px;
  pointer-events: none;
}
.navigation .options li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: var(--main-white);
}
.navigation .options li:hover a,
.navigation .options li.hovered a {
  color: var(--main-blue);
}
.navigation .options li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 2.5rem;
}
.navigation .options li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}

/* curva de afuera */

.navigation .options li:hover a::before,
.navigation .options li.hovered a::before {
  content: "";
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px var(--main-white);
  pointer-events: none;
}
.navigation .options li:hover a::after,
.navigation .options li.hovered a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px var(--main-white);
  pointer-events: none;
}

/* Logout */

.lastset {
  height: 100%;
  align-items: flex-end;
  display: flex;
  padding-bottom: 20px;
}
.lastset ul {
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 50px;
  cursor: pointer;
}
.lastset ul:hover {
  transform: scale(1.05) perspective(0px);
  box-shadow: 50px;
  transition-delay: 0.1s;
}
.lastset ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.lastset ul li:nth-child(1) {
  margin-bottom: 25px;
  /* pointer-events: none; */
}
.lastset ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: var(--main-white);
}
.lastset ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 2.5rem;
}
.lastset ul li a .title {
  position: relative;
  display: block;
  padding: 0 12px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}

@media (max-width: 950px){
  .sidebar{
    display: none;
    visibility: hidden;
  }
}

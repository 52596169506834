::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.container {
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  margin-top: 10px;
}

.leftside {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  gap: 10px;
  overflow-y: scroll; 
  flex-grow: 1;
}

.rightside {
  width: 30%;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0 15px 10px 10px;
  position: sticky;
}

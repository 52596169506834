.create_license{
    width: 100%;
}
.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.inputs_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}
.left_side{
    width: 50%;
}
.right_side{
    width: 50%;
    margin-left: auto;
}
.container_row{
    width: 100%;
    display: flex;
    gap: 2rem;
}
@media (max-width: 950px){
    .container_row{
        flex-direction: column;
    }
    .left_side{
        width: 100%;
    }
    .right_side{
        width: 100%;
    }
}
/* InstitutionCalendar.module.css */
.legend {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: end;
    margin-right: 15px;
  }
  
  .legendItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .legendColor {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }

.eventBachillerato {
    background-color: var(--sec-orange);
    border-color: var(--sec-orange);
  }
  
  .eventPrimaria {
    background-color: var(--sec-green);
    border-color: var(--sec-green);
  }
  
  .eventPreescolar {
    background-color: var(--sec-blue);
    border-color: var(--sec-blue);
  }
  
.pdf-container {
    max-height: 400px; 
    min-height: 200px;
    overflow-y: auto; 
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
  }
  
  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: stretch; 
  }
  
  .buttons {
    display: flex;
    width: 100%; 
    justify-content: space-between;
  }
  
  .buttons > button {
    flex: 1; 
  }
  .custom-label .MuiFormControlLabel-label {
    font-size: 1.2rem; /* Cambia el tamaño de la fuente */
    font-weight: bold; /* Opcional: grosor */
    margin-bottom: 5px;
  }
  
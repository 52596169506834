.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
}

.form__fields {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  border-radius: var(--regular-border-radius);
  align-items: center;
  justify-content: center;
}

.attachment {
  background-color: var(--main-blue);
  border-radius: var(--regular-border-radius);
}

.attachment:hover {
  background-color: var(--sec-purple);
}

.attachment input {
  display: none;
}

.attachment i {
  font-size: 2rem;
  color: var(--main-white);
  padding: 10px 15px;
  cursor: pointer;
}

.message-input {
  display: flex;
  width: 100%;
}

.content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .leftside {
    display: flex;
    flex-grow: 1;
  }
  
  .rightside {
    flex-direction: column;
    margin: 0 15px;
  }
  
  .options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  
  .image {
    width: 55px;
    display: flex;
    margin: 10px 0;
  }
  .image img {
    width: 45px;
    border-radius: 50%;
  }
  @media (max-width: 950px){
    .content {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .rightside {
      flex-direction: row;
      margin: 0 15px;
    }

  }
.filter_textSelect {
    width: 100%;
    height: 24px;
    border-radius: var(--regular-border-radius);
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
    margin-top: 10px;
    margin-bottom: 5px;
  }

.button{
    width: fit-content;
    background-color: var(--main-blue);
    color:#fff;
    border-radius: 10px;
    padding: 10px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
}


.button2{
  width: fit-content;
  background-color: var(--sec-orange);
  color:#fff;
  border-radius: 10px;
  padding: 10px;
  border: none;
  margin-top: 10px;
  cursor: pointer;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.content {
max-width: 100%;
display: grid;
grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
padding: 5px 2px 5px 5px;
margin: 0 auto;
overflow-y: scroll;
}
.selectcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  margin-top: 10px;
  margin-bottom: 2px; /* Add vertical space below the label */
  font-weight: bold;
  font-size: 1.2rem;
}

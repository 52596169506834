.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 84vh;
  margin-right: 10px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}

.search {
  height: 30px;
  margin-left: auto;
  border-radius: var(--regular-border-radius);
  width: 250px;
  background-color: #f5f5f5;
}

.title {
  font-size: 2rem;
  font-weight: 800;
  align-self: flex-start;
}

.courses {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
}

.courses > a {
  text-decoration: none;
  color: inherit;
}

.course .button button {
  cursor: pointer;
  border-radius: var(--regular-border-radius);
  background-color: var(--main-white);
  color: #000;
  border-color: var(--ter-blue);
}
.course .button button:hover {
  background-color: var(--ter-blue);
  color: var(--main-white);
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: auto;

}

.pagination button {
  background-color: var(--ter-blue);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #555;
}

.pagination span {
  margin: 0 5px;
}
@media (max-width: 750px){
  .wrapper {
    margin-right: 0px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  overflow-y: scroll;
}

.content > div {
  width: 100%;
}
.content2 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: row;
  gap: 1rem;
}

.search-bar {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: flex-end;
}
.search-bartwo {
  display: flex;
  width: 150px;
  height: 45px;
  justify-content: center;
}

.filter_text {
  width: 20%;
  height: 30px;
  border-radius: 10px;
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
}
.image {
  width: 35px;
  display: flex;
  margin: 10px 0;
}
.image img {
  width: 30px;
  border-radius: 50%;
}

.upload {
  display: flex;
  background-color: var(--main-blue);
  border-radius: var(--regular-border-radius);
  padding: 5px 10px;
}

.upload:hover {
  background-color: var(--sec-purple);
}

.upload_link {
  color: var(--main-white);
  font-size: 1.25rem;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 600px) {
  .search-bartwo {
    width: 130px; /* Hacer que ocupe el 100% en pantallas pequeñas */
    margin-left: 0; /* Quitar el margen en pantallas pequeñas */
  }
  .search-bar {
    width: 100%; /* Hacer que ocupe el 100% en pantallas pequeñas */
    margin-left: 0; /* Quitar el margen en pantallas pequeñas */
  }
}

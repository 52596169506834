.conversation {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-left: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1.5rem 3rem;
  width: 100%;
  height: calc(100vh - 80px - 2rem);
}

.messages {
  overflow-y: auto;
  overflow-x: auto;
}

.message {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.info {
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #858585;
  margin-bottom: 0.5rem;
}

.status {
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #858585;
  margin-bottom: 0.5rem;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E9E9EB;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 20px;
  color: #000;
  width: fit-content;
  gap: 1rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  max-width: 65%;
  flex-direction: column;
}

.text_own {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007AFF;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 20px;
  color: #fff;
  gap: 1rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  max-width: 70%;
}

.own {
  align-self: flex-end;
  margin-right: 20px;
  flex-direction: column;
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text:hover .delete > svg {
  visibility: visible;
}

.delete > svg {
  transform: scale(0.75);
  visibility: hidden;
  fill: #858585;
}

.delete > svg:hover {
  fill: red;
}

.new-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f0f0f0;
  margin-top: 1rem;
  background: #fff;
  border-radius: 1.5rem;
  padding: 0.25rem;
  width: 100%;
}

.new-message > textarea {
  margin-left: 1rem;
  height: auto; 
  max-height: 150px;
  border: none;
  outline: none;
  font-size: 10px;
  mix-blend-mode: normal;
  width: 100%;
  padding-right: 10px;
  overflow-y: auto;
  resize: none;
}

.new-message > button {
  background: var(--main-blue);
  /* padding: 0.5rem 1.25rem; */
  border: none;
  border-radius: 100px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.375px;
  text-transform: uppercase;
  cursor: pointer;
}

.new-message > button > i{
  font-size: 1.7rem;
  padding: 10px 15px;
}

.new-message > button:hover {
  background: var(--main-blue);
}

.no-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  margin: auto;
  padding: 1rem;
  border-radius: 10px;
  width: fit-content;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}
.unread {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}

.unread-text {
  background: var(--main-blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.375px;
  text-transform: uppercase;
}

.unread-line {
  background: var(--main-blue);
  height: 2px;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}
.attachment {
  background-color: var(--main-blue);
  border-radius: 20px;
  margin-left: 5px;
}

.attachment:hover {
  background-color: var(--sec-purple);
}

.attachment input {
  display: none;
}

.attachment i {
  font-size: 1.7rem;
  color: var(--main-white);
  padding: 10px 15px;
  cursor: pointer;
}
.attachment-container{
  text-decoration: none;
}
.attachment_message{
  cursor: pointer;
  padding: 5px;
  color:#fff;
  text-decoration: none;

}
.attachment_message i{
  font-size: 1.6rem;
  color: var(--main-white);
  margin-right: 5px;

}
.form__fields{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 750px){
  .conversation{
    border-left: 2px solid #f0f0f0;
    border-radius: 10px;
    padding: 1rem 1rem;
    height: calc(95vh - 80px - 2rem);
    border: none;
  }

  .text {
    overflow-wrap: break-word;
    word-break: break-all
  }
  .text_own {
    overflow-wrap: break-word;
    word-break: break-all
  }
}

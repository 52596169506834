.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 20px;
    justify-content: center;
    align-items: center;
}
.create-question{
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: 81vh;
    overflow-y: scroll;
}
.inputs {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 1rem;
}
.add_opt{
    display: 'flex';
    flex-direction: column;
}

.select_field {
    justify-content: space-between;
}
.select_input{
    margin-bottom: 3px;
    font-size: 1.5rem;
}
  .select {
    border-radius: var(--regular-border-radius);
    font-size: 1.5rem;
    padding: 8px;
    width: 230px;
    border: 1px solid var(--main-gray);
  }
  .text_area{
    width: 100%;
  }
  .textarea {
    border-radius: var(--regular-border-radius);
    border: 1px solid var(--main-gray);
    padding: 1rem;
    overflow-y: scroll;
    resize: none;
    margin-top: 5px;
    width: 100%;
  }
.questions_container{
    display: flex;
    flex-direction: column; 
    margin-bottom: 15px;
}
.question_content{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.text_question{
    font-size: 16px; 
    margin-bottom: 5px; 
    width: 470px
}
.check_level{
    margin-bottom: 5px;
  }
  .inst{
    max-height: 180px;
    overflow-y: scroll;
  }
  .inst::-webkit-scrollbar {
  display: none;
  }
  .search-bar {
    display: flex;
    width: 90%;
    justify-content: flex-start;
    margin-bottom: 1rem;
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .filter_text {
    width: 55%;
    height: 24px;
    border-radius: var(--regular-border-radius);
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
  }
@media (max-width: 590px){
    .create-question{
        overflow-x: hidden;
    }
    /* .inputs {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin-top: 1rem;
        width: 100%;
    } */

.inputs {
  display: flex;
  flex-direction: row; /* Alinear en una fila */
  gap: 10px; /* Espacio entre los elementos */
  margin-top: 1rem;
  align-items: center; /* Alinear verticalmente los elementos */
  width: 100%; /* Ancho completo */
}

.textarea-container {
  flex-grow: 1; /* Para que el textarea ocupe el espacio restante */
}

.textarea {
  width: 100%; /* Asegura que el textarea ocupe todo el espacio disponible */
  min-width: 200px; /* Ajusta el tamaño mínimo para el textarea */
}
    .questions_container{
        display: flex;
        flex-direction: column; 
        margin-bottom: 15px;
        width: 100%;
    }
    .text_question{
        font-size: 16px; 
        margin-bottom: 5px; 
        width: fit-content;
    }

    /* .textarea {
        border-radius: var(--regular-border-radius);
        border: 1px solid var(--main-gray);
        padding: 1rem;
        overflow-y: scroll;
        resize: none;
        margin-top: 5px;
        width: 100%;
    } */
    .comments_content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    }
    .select_field{
      width: 100%;
    }
    .select{
      width: 100%;
    }
    

}
.survey_content{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
}

.btn_download{
    font-size: 1.3rem;
    border-radius: 10px;
    background-color: var(--main-blue); 
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    color: #fff;
}


.sv_main .sv_btn {
  border-radius: 10px !important;
}
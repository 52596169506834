.option {
  padding: 20px;
  display: flex;
  justify-content: center;
  border-radius: var(--regular-border-radius);
  background-color: var(--main-white);
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  width: 120px;
  height: 120px;
}

.placeholder {
  padding: 20px;
  display: flex;
  justify-content: center;
  border-radius: var(--regular-border-radius);
  flex-direction: column;
  text-align: center;
  width: 120px;
  height: 120px;
}

.option_2 {
  padding: 5px 10px;
  display: flex;
  border-radius: var(--regular-border-radius);
  flex-direction: row;
  cursor: pointer;
  width: fit-content;
  gap: 5px;
  align-items: center;
}

.option_3 {
  padding: 5px 10px;
  display: flex;
  border-radius: var(--regular-border-radius);
  background-color: var(--main-white);
  flex-direction: row;
  cursor: pointer;
  width: fit-content;
  box-shadow: 0 0 5px -1px var(--main-gray);
  gap: 5px;
  align-items: center;
}
.link {
  text-decoration: none;
  color: black;
}
.option:hover {
  box-shadow: 0 0 7px -1px var(--main-gray);
}
.option_2:hover {
  background-color: #DDE1E7;
}

.option .icon,
.placeholder .icon {
  font-size: 3.5rem;
  --fa-primary-color: var(--ter-blue);
  --fa-secondary-color: var(--ter-blue);
  color: var(--ter-blue);
}
.option_2 .icon {
  color: #51585F;
  font-size: 1.8rem;
}
.option_3 .icon {
  color: var(--ter-blue);
  font-size: 1.8rem;
}
.option .title,
.placeholder .title {
  font-size: 1.4rem;
  margin-top: 8px;
}
.option_2 .title {
  font-size: 1.2rem;
  color: #51585F;
}
.option_3 .title {
  font-size: 1.2rem;
  color: var(--ter-blue);
}
@media (max-width: 950px){
  .option_3 {
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 125px;
    height: 50px;
    gap: 5px;
  }
  .option_3 .icon {
    font-size: 1.5rem;
  }
  .option_3 .title {
    font-size: 1.2rem;
    margin-top: 0;
  }
  .option_2 {
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 125px;
    height: 50px;
    gap: 5px;
  }
  .option_2 .title {
    font-size: 1.2rem;
    margin-top: 0;
  }
  .option_2 .icon {
    font-size: 1.5rem;
  }

}
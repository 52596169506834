.navbar {
  height: 65px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--main-white);
  width: 100%;
}
.navbar_mobile {
  height: 65px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--main-white);
  margin-left: 6rem;
}
.container {
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  margin-top: 10px;
  margin-left: 30px;
  height: 60px;
}
.logo {
  justify-self: start;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.logo img {
  scale: 1.25;
  width: 175px;
}

.components {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
}

.item {
  display: flex;
  gap: 10px;
}

.item .link {
  color: var(--main-blue);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 2px;
  height: 100%;
  font-size: 1.5rem;
}

.item .link:last-child {
  padding: 5px 0 5px 20px;
}

.profile {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.75rem;
}

.profile > i {
  font-size: 2.5rem;
}

.name {
  display: flex;
  align-items: center;
  gap: 10px;
}

.name > i {
  font-size: 2rem;
}

.link > i {
  font-size: 2.5rem;
  text-decoration: none;
  color: var(--main-blue)
}

.notification_container {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background-color: #ffffff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  z-index: 1;
  max-height: 500px;
  overflow-y: scroll;
}

.notification_item {
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.notification_item:last-child {
  border-bottom: none;
}
.notification_dot {
  width: 8px;
  height: 8px;
  background-color: var(--main-blue);
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  margin-left: auto;
}
.notification_text p {
  font-size: 14px;
  font-weight: bold;
  text-align: start;
}

.notification_text_read p {
  font-size: 14px;
  text-align: start;
}

.notifications {
  color: var(--main-blue);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 5px 10px;
  height: 100%;
  font-size: 1.5rem;
  width: 24px;
  justify-content: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.notifications > i {
  font-size: 2.3rem;
}
.icon_dot {
  width: 15px;
  height: 15px;
  background-color: var(--sec-red);
  border-radius: 50%;
  position: absolute;
  margin-bottom: 2rem;
  margin-left: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
}
.icon_dot > p {
  font-weight: 700;
  font-size: 0.8rem;
  color: #fff;
}
.icon_dot_chat {
  width: 15px;
  height: 15px;
  background: var(--sec-red);
  border-radius: 50%;
  position: absolute;
  margin-bottom: 2rem;
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
}
.icon_dot_chat > p {
  font-weight: 700;
  font-size: 0.8rem;
  color: #fff;
}
.chat_dot {
  width: 8px;
  height: 8px;
  background-color: var(--sec-orange);
  border-radius: 50%;
  position: absolute;
  margin-bottom: 1.7rem;
  margin-left: 2.2rem;
}
.menu_item{
  visibility: hidden;
  display: none
}

.list{
  visibility: hidden;
  display: none
}
.item_profile{
  margin-right: 15px;
  display: flex;
  align-items: center;
}
@media (max-width: 950px){
  .navbar {
    height: 62px;
    background-color: var(--main-blue);
    margin: 0;
  }
  .item_profile {
    visibility: hidden;
    display: none
  }
  .menu_item{
    display: flex;
    visibility: visible;
  }
  .menu_item i{
    font-size: 25px;
    color: var(--main-white);
    cursor: pointer;
    margin-right: 15px;
  }
  .list {
    visibility: visible;
    list-style-type: none;
    background: var(--ter-purple);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .logo img {
    scale: 1.2;
    width: 150px;
    padding: 6px
  }

  .item .link {
    color: var(--main-white);
  }
  .item .link i {
    color: var(--main-white);
    font-size: 2.6rem;
  }

  .notifications {
    color: var(--main-white);
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .notifications > i {
    font-size: 2.6rem;
  }

  .icon_dot > p {
    color:  var(--main-white);
  }
  .icon_dot_chat{
    background-color: var(--main-white);
  }
  .icon_dot_chat > p {
    color: var(--main-blue);
  }
}
.lesson_info{
    margin-top: 5px;
    width: 100%;
}
.text {
    font-size: 1.3rem;
    line-height: 2rem;
    padding-top: 5px;
}
.text_2 {
    font-size: 1.3rem;
    padding-top: 3px;
    padding-bottom: 7px;
}
.container_text{
    border-radius: var(--regular-border-radius);
    padding: 10px 20px;
    margin-bottom: 10px;
    background-color: #fff;
    font-size: 1.3rem;
}
.title_text{
    font-size: 1.5rem;
    padding-bottom: 5px;
    font-style: italic;
    font-weight: 600;
}
.resources_videos{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 5px;
}
.curriculum_title{
    font-size: 1.3rem;
    font-style: italic;
    margin-bottom: 5px;
    font-weight: bold;
}
.curriculum_text{
    font-size: 1.3rem;
    margin-bottom: 3px;
}
.title_container{
    display: flex;
    align-items: center;
}
.title_container .report{ 
    margin-left: auto;
    font-size: 14px;
    border: 1px solid var(--sec-red);
    padding: 5px;
    border-radius: var(--regular-border-radius);
    color: var(--sec-red);
    cursor: pointer;
}
.title_container .report:hover{
    background-color: var(--ter-red);
    color:#fff
}
.form__fields{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
select{
    border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 5px;
  width: 230px;
  border: 1px solid var(--main-gray);
  margin-bottom: 10px;
}
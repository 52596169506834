.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    max-height: 84vh;
    overflow-y: scroll;
    margin-bottom: 15px;
    margin-top: 10px;
}
.questions_total{
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  margin-top: 10px;
  border-radius: 10px;
}
.search_bar {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-left: auto;
}
.filter_text {
  width: 35%;
  height: 30px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
  margin: 0 2rem 0 0;
}
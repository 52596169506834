.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
  }
  
  .leftside {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  @media (max-width: 950px){
    .content{
      flex-direction: column;
    }
  }
  
.profile {
  display: flex;
  overflow: hidden;
}
.header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header > h1 {
  margin: 0;
  font-size: 3rem;
  color: var(--main-blue);
}

.header > i {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 1.5rem;
  border-radius: 50%;
  color: var(--main-blue);
  border: 1px solid var(--main-blue);
  height: 30px;
  width: 30px;
}

.header > i:hover {
  color: var(--main-white);
  background-color: var(--main-blue);
  border: 1px solid var(--main-blue);
  cursor: pointer;
}

/* Side personal info */

.main-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px 0 30px;
  color: var(--main-gray);
  position: fixed;
  width: 150px;
}

.main-info > i {
  font-size: 10rem;
  color: var(--light-gray);
}
.main-info__name {
  display: flex;
  align-self: flex-start;
  margin: 20px 0 0 0;
  font-size: 2rem;
  font-weight: 900;
  color: var(--main-blue);
}

.main-info__role {
  display: flex;
  align-self: flex-start;
  font-size: 1.5rem;
}

.main-info__contact {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding: 20px 0;
  gap: 10px 0;
  border-top: 1px solid var(--light-gray);
}

.main-info__item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(100% - 210px);
  position: relative;
  left: 210px;
  overflow-y: auto;
  padding-right: 10px;
}

.content h2 {
  font-size: 2rem;
  font-weight: 200;
  margin: 0;
  padding: 20px 0 0 20px;
}
.monitor{
  background-color: #fff;
  margin: 10px 20px 20px 20px;
  border-radius: 10px;
  padding: 1rem;
}
.diplomado{
  background-color: var(--sec-purple);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  margin-top: 20px;
  font-size: 15px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.aboutdiplomado{
  background-color: var(--sec-purple);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  margin-top: 20px;
  font-size: 15px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.profile-icon-edit {
  position: relative;
  display: inline-block;
}
.edit-button {
  position: absolute;
  top: 0; /* Ajusta la posición como necesites */
  right: 0; /* Ajusta la posición como necesites */
  background-color: transparent; /* Hace el fondo transparente */
  border: none; /* Remueve el borde */
  cursor: pointer; /* Cambia el cursor a un puntero */
}
.edit-button i {
  color: #000; /* Ajusta al color que necesites */
  background-color: #fff; /* Ajusta al color de fondo que necesites */
  border-radius: 50%; /* Hace que el fondo sea circular */
  padding: 5px; /* Añade espacio alrededor del ícono */
}
@media (max-width: 950px){
  .profile {
    display: flex;
    flex-direction: column;
  }
  .main-info {
    align-items: center;
    margin: 0;
    position:relative;
    width: 100%;
  }
  .main-info__name {
    align-self: center;
  }
  .main-info__role {
    align-self: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    left: 0px;
    padding-right: 0;
    margin-top: 20px;
  }
  

}
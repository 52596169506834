.teachers_info{
  max-height: 87vh;
  overflow-y: scroll;
}
.questions_total{
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  margin-top: 10px;
  border-radius: 10px;
}
.download{
  margin-bottom: 10px;
  border: 1px solid var(--main-blue);
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: var(--main-blue);
  color: #fff;
}
.content {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: flex-end;
}
  .filter_text {
    width: 50%;
    height: 30px;
    border-radius: var(--regular-border-radius);
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
  }
  .image {
    width: 35px;
    display: flex;
    margin: 10px 0;
  }
  .image img {
    width: 30px;
    border-radius: 50%;
  }
  .teachers_info{
    padding: 2rem;
  }
  .data_table{
    border-radius: var(--regular-border-radius);
    margin-top: 1rem;
  }
  .select {
    width: 50%;
    height: 30px;
    border-radius: var(--regular-border-radius);
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
    margin-left: 5px;
  }
  .input_box{
    display: flex;
    width: fit-content;
    justify-content: flex-end
  }
@media (max-width: 950px){
  .content {
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    padding-top: 5px;
  }
  .input_box{
    justify-content: center;
    width: 100%;
  }
  .filter_text {
    width: 50%;
  }
  .select {
    width: 50%;
  }
  
}
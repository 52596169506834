.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    overflow-y: scroll;
}
.search-bar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
  
.filter_text {
    width: 30%;
    height: 30px;
    border-radius: 10px;
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
}
.filters{
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
}
.filters select{
    width: 30%;
    height: 30px;
    border-radius: 10px;
    font-size: 1.2rem;
    border: 1px solid var(--main-gray);
}
.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 20px;
    align-items: flex-start;
}
.inputs{
    display: flex;
    gap: 1rem
}
.texteditor{
    display: flex;
    flex-direction: column;
    width: 470px
}
@media (max-width: 950px){
    .inputs{
        flex-direction: column;
        width: 100%;
    }
    .texteditor{
        width: 370px;
    }
    .form__fields{
        padding: 0px;
    }
}
.main {
  display: flex;
  flex-direction: row;
  height: 87vh;
}
.leftside {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 10px;
  width: 80%;
}
.opening {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: var(--regular-border-radius);
}
.opening .name {
  font-size: 2.6rem;
  font-weight: 500;
  margin: 0;
  padding-bottom: 10px;
}
.opening .logos {
  /* width: 20%; */
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.opening .date {
  font-size: 15px;
  color: #787878;
}

.cursos{
  margin-top: auto;
}
.carousel{
  display: grid;
  position: relative;
}
.carousel_start{
  display: grid;
  position: relative;
  padding-right: 3rem;
}

.carousel_end{
  display: grid;
  position: relative;
  padding-left: 3rem;
  padding-right: 3rem;
}

.customCarouselArrow {
  width: 23px; 
  height: 23px; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5; 
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2rem; 
  color: #000; 
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2); 
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.customCarouselArrowLeft {
  left: 0; 
}

.customCarouselArrowRight {
  right: 0; 
}

@media (max-width: 950px){
  .main {
    display: flex;
    flex-direction: column;
    height: fit-content;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .leftside {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }

}
.form__fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: var(--main-white);
  border-radius: var(--regular-border-radius);
  padding: 20px;
  justify-content: center;
  align-items: center;
}
.create-program {
  width: 100%;
}
.inputs_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}
.form_container{
  display: flex;
  flex-direction: row;
}
.inputs_right{
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
.inputs_right p{
  color: black;
  font-size: 1.5rem;
}
.search-bar {
  display: flex;
  width: 90%;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.search-bar select{
  width: 50%;
  border-radius: var(--regular-border-radius);
}
.filter_text {
  width: 55%;
  height: 24px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
}
.levels{
  max-height: 250px;
  overflow-y: scroll;
}
.form_rows {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.wrapper {
  border: 1px solid var(--main-blue);
  border-radius: var(--regular-border-radius);
  padding: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  width: 230px;
  cursor: pointer;
  margin-top: 10px;
}
.wrapper:hover {
  border: 1px solid var(--ter-blue);
  background-color: var(--ter-blue);
}
.wrapper:hover p {
  color: var(--main-white);
}
.wrapper p {
  margin: 0;
  padding: 5px;
}

.uploadedarea {
  width: 230px;
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.content {
  width: 230px;
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 1rem;
  border: 1px solid var(--ter-blue);
  border-radius: var(--regular-border-radius);
  padding: 10px;
}
.content .name {
  padding-left: 10px;
  margin: 0;
}

.add_levels{
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}
.add_levels p{
  cursor: pointer;
}
.add_opt input{
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 230px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid var(--main-gray);
  height: 35.5px;
}
.add_opt select{
  border-radius: var(--regular-border-radius);
  font-size: 1.5rem;
  padding: 8px;
  width: 230px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid var(--main-gray);
  height: 35.5px;
}
.inputs {
  display: flex;
  gap: 20px;
  margin-top: 1rem;
  align-items: flex-end;
}
@media (max-width: 950px){
  .inputs_left{
    flex-direction: column;
    width: 100%;
  }
  .form_container{
    flex-direction: column;
  }
  .inputs{
    flex-direction: column;
  }
}
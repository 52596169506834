.left {
  display: flex;
  flex-direction: column;
}
.left .title {
  font-size: 14px;
}
.title{
  font-size: 14px;
  font-weight: bold;
}

.time {
  font-size: 12px;
}

.card_reminder {
  background: #fff;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 1rem 1rem 1rem 2rem;
  border-radius: var(--regular-border-radius);
  align-items: center;
  cursor: pointer;
}


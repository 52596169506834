.empty-card {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--regular-border-radius);
  background-color: var(--background-gray);
  padding: 30px;
}
.card_header {
  display: flex;
  justify-content: space-between;
  border-radius: var(--regular-border-radius);
  background-color: var(--background-gray);
  padding: 10px;
  width: 30%;
}
.card {
  display: flex;
  align-items: center;
  border-radius: var(--regular-border-radius);
  background-color: var(--background-gray);
  padding: 10px;
}
.card_option {
  display: flex;
  align-items: center;
  border-radius: var(--regular-border-radius);
  background-color: #fff;
  padding: 3px 10px;
  cursor: pointer;
}
.card_option_selected {
  display: flex;
  align-items: center;
  border-radius: var(--regular-border-radius);
  background-color: #fff;
  padding: 3px 10px;
  cursor: pointer;
  box-shadow: 0 0 5px -1px var(--main-gray);
}
.card_option:hover {
  box-shadow: 0 0 5px -1px var(--main-gray);
}

.first-block {
  display: flex;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logos > img {
  height: 40px;
}

.info {
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* width: 300px; */
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
}

.small {
  display: flex;
  gap: 5px;
  font-size: 1.25rem;
  align-items: center;
}

.small > i {
  margin-right: 3px;
  font-size: 10px;
}

.mid-block {
  display: flex;
  align-items: center;
}

.notifications {
  display: flex;
  align-items: center;
  border-radius: var(--regular-border-radius);
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--sec-yellow);
  gap: 5px;
  padding: 0 10px;
}

.last-block {
  display: flex;
  align-items: center;
  gap: 10px;
}
.lessons {
  font-size: 1.5rem;
}

.progressbar {
  display: flex;
  align-items: center;
  background-color: var(--main-white);
  border-radius: var(--regular-border-radius);
  width: 150px;
  height: 15px;
  text-align: center;
}

.progress {
  background-color: var(--sec-green);
  border-radius: var(--regular-border-radius);
  width: 50%;
  height: 100%;
}

/* Course Card header styles */

.header-logo {
  width: 100%;
  max-width: 130px;
  max-height: 110px;
  object-fit: contain;
}

.header-title {
  font-size: 1.4rem;
}
.card_header .header-title {
  font-size: 1.5rem;
  font-weight: 560;
}

.header-subtitle {
  font-size: 1.3rem;
}

.header-info {
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.class-info{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 10px;
}

/* Course Teacher Card styles */

.user-picture > i {
  font-size: 2.3rem;
}
/* Course Sections Card styles */

.sections {
  background-color: var(--sec-blue);
  color: var(--main-white);
  border-radius: 50%;
  font-size: 1.3rem;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.sections > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

/* Forum card styles */

.forum-notifications {
  display: flex;
  align-items: center;
  border-radius: var(--regular-border-radius);
  font-size: 3rem;
  color: var(--sec-yellow);
  gap: 5px;
  margin-left: auto;
}
/* Classes card styles */
.classes-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--regular-border-radius);
  background-color: var(--main-blue);
  width: 34px;
  height: 34px;
}
.classes-icon i{
  font-size: 1.5rem;
  color: #fff;
}

/* Data Bank */

.databank-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--regular-border-radius);
  /* background-color: var(--background-gray); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* padding: 10px 40px 10px 0; */
  padding: 10px;
  width: 100%;
}
.databank-card:hover {
  border-radius: var(--regular-border-radius);
  background-color: var(--background-gray);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* padding: 10px 40px 10px 0; */
  width: 100%;
}

.db-first-block {
  display: flex;
  justify-content: flex-start;
}

.info {
  /* margin: 0 30px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 300px; */
}
.courseData{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 750px){
  .card {
    padding: 10px;
    justify-content: flex-start;
    gap: 10px;
  }
  .info {
    margin: 0px;
  }
  .courseData{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3px;
  }
}
@media (max-width: 950px){
  .card_header{
    width: 100%;
  }

}

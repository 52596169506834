.go_back {
  padding: 2rem 1rem 0.5rem 1rem;
}
.topic {
  width: 100%;
  max-height: 67vh;
}
.topic_content {
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 67vh;
}
.topic_left {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  height: 100%;
}
.left_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topic_left p {
  font-size: 25px;
  color: "#989a9e";
}
.topic_right {
  margin-left: 5px;
  width: 92%;
  margin-right: 1rem;
  max-height: 67vh;
}
.topic_right h2 {
  font-size: 20px;
  margin: 0;
}
.topic_right .description {
  font-size: 15px;
  margin-top: 1.5rem;
  line-height: 2rem;
}
.topic_bottom {
  border-top: 1px solid var(--main-gray);
  margin-top: 2rem;
  padding-top: 5px;
}
.topic_bottom .button {
  margin-top: 1rem;
  cursor: pointer;
  font-size: 13px;
  color: var(--ter-blue);
}
.msg_box {
  background-color: var(--main-white);
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-radius: var(--regular-border-radius);
  gap: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.sender {
  font-size: 15px;
  font-weight: bold;
  color: var(--main-blue);
}
.mesg {
  font-size: 13px;
  /* margin-left: 1rem; */
}

.readby {
  text-align: end;
}

.header {
  width: 100%;
  display: flex;
}
.sender_date {
  margin-left: auto;
  font-size: 11px;
  font-style: italic;
  color: var(--main-gray);
}
.add_comment {
  width: 100%;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.add_comment p {
  font-size: 14px;
  color: var(--ter-blue);
  cursor: pointer;
}
.mesgs_list {
  overflow-y: scroll;
  max-height: 42vh;
}

.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.status_active {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #2ec401;
  margin-left: auto;
}
.status_closed {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #f7251d;
  margin-left: auto;
}

.attachment-container {
  text-decoration: none;
  color: var(--main-blue);
  background-color: var(--background-gray);
  border-radius: var(--regular-border-radius);
  cursor: pointer;
}

.attachment-container:hover {
  color: var(--main-white);
  background-color: var(--main-blue);
}

.attachment {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-size: 1.4rem;

  padding: 10px;
}

.attachment a {
  text-decoration: none;
}

.button{
    background-color: var(--main-blue);
    color: #fff;
    border: none;
    padding: 1rem;
    width: fit-content;
    border-radius: 10px;
    cursor: pointer;
}
.buttonNotes{
    background-color: #fff;
    color: #fff;
    border: 1px solid var(--main-blue);
    padding: 7px;
    width: fit-content;
    border-radius: 10px;
    cursor: pointer;
    color: var(--main-blue);
    margin-top: 10px;

}
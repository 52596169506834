.contacts {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-top-left-radius: var(--regular-border-radius);
  border-bottom-left-radius: var(--regular-border-radius);
  height: calc(100vh - 80px - 2rem);
}

.user-info {
  display: flex;
  padding: 1rem;
  width: 301px;
}

.user-info p {
  font-size: 16px;
  font-weight: bold;
}
.search-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin: 1rem;
}
.filter_text {
  width: 100%;
  height: 28px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
}

.chat-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.contact {
  padding: 0.7rem 0.5rem 0.7rem 1rem;
}

.contact:hover {
  background-color: #f0f0f0;
}

.active {
  background-color: #f0f0f0;
}

.new {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem 0 1.5rem 0;
}
@media (max-width: 750px){
  .contacts{
    width: 100%;
    border: none;
    border-radius: var(--regular-border-radius);
    height: calc(93vh - 80px - 2rem);
  }
  .user-info{
    width: 100%;
  }
}

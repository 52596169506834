.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.content {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  padding: 5px 2px 5px 5px;
  margin: 0 auto;
  overflow-y: scroll;
}
.search-bar {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.filter_text {
  width: 30%;
  height: 30px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
  margin-left: 5px;
}
.course {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  width: 210px;
  height: 200px;
  border-radius: var(--regular-border-radius);
  background-color: var(--main-white);
  cursor: pointer;
}
.course:hover {
  box-shadow: 0 0 7px -1px var(--ter-blue);
}
.course .logos {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid var(--ter-blue);
}
.logo-img {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  width: 60px;
  height: 60px;
}

.course .info {
  padding: 10px;
  height: 100px;
}
.course .info h2 {
  font-size: 1.5rem;
  margin: 5px 0 5px 0;
  text-align: start;
}
.course .info h3 {
  margin: 0;
  text-align: start;
}
.course .info p {
  font-size: 1.2rem;
  padding-top: 5px;
}
.course .button {
  margin: 0 20px;
}
.course .button button {
  margin: 0;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: var(--regular-border-radius);
  background-color: var(--main-white);
  border-color: var(--ter-blue);
  color: var(--ter-blue);
}
.course .button button:hover {
  background-color: var(--ter-blue);
  color: var(--main-white);
}
@media (max-width: 950px){
  .filter_text {
    width: 50%;
  }
  .content {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    padding: 0;
  }
  .course {
    width: 170px;
    height: 150px;
  }
  .course .logos {
    height: 55px;
  }
  .logo-img {
    display: flex;
    justify-content: center;
    margin-top: 2px;
    width: 50px;
    height: 50px;
    padding-bottom: 5px;
  }
  .course .info {
    padding: 5px;
    height: 90px;
  }
  .course .info h2 {
    font-size: 1.3rem;
    margin: 5px 0 0px 0;
    text-align: start;
  }

}

.content {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-end;
}
.filter_text {
  width: 100%;
  height: 30px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
}
.image {
  width: 35px;
  display: flex;
  margin: 10px 0;
}
.image img {
  width: 30px;
  border-radius: 50%;
}
.teachers_info{
  padding: 2rem;
}
.data_table{
  border-radius: var(--regular-border-radius);
}
.select {
  width: 100%;
  height: 30px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
  margin-left: 5px;
}
.filter{
  display: flex;
}
@media (max-width: 950px){
  .content{
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
  .select{
    width: 50%;
  }
  .filter_text{
    width: 50%;
  }
  .filter{
    width: 100%;
  }
}
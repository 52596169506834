.entry-detail {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  width: 80%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: var(--regular-border-radius);
}

.entries {
  background-color: var(--background-gray);
  overflow-y: scroll;
}

.entry-header {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  padding: 20px;
  text-align: justify;
}
.entry-header > h3 {
  font-size: 3rem;
}

.program-entry {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  /* padding: 20px; */
  border-bottom: 3px solid var(--main-white);
  width: 100%;
}

.program-entry:last-child {
  border-bottom: none;
}

.first-block {
  width: 15%;
  padding: 20px;
  border-right: 3px solid var(--main-white);
}

.program-entry .program {
  font-size: 2rem;
  font-weight: 800;
}

.program-entry .description {
  display: flex;
  justify-content: flex-start;
  text-align: justify;
  padding: 20px;
  width: 65%;
}

.program-entry img {
  width: 150px;
  padding: 10px;
}

.image {
  display: flex;
  align-items: center;
  background-color: var(--main-white);
  border: 1px solid var(--background-gray);
  border-radius: var(--regular-border-radius);
  margin: 20px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.search {
  background-color: #fff;
  padding: 1rem 2rem;
}
.user-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}

.user-img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-blue);
  color: #fff;
  height: 3.7rem;
  width: 3.7rem;
  border-radius: 50%;
  /* margin-right: 0.5rem; */
}
.status {
  position: absolute;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  left: 2.6rem;
  top: 2.8rem;
}

.online {
  background: #8cee5d;
}

.offline {
  background: #dedede;
}

.user-col {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.user-name {
  font-size: 1.4rem;
  font-weight: 700;
  color: #858585;
  width: 100%;
}

.user-message2 {
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 5px;
  color: #858585;
}

.user-message {
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 5px;
}
.user-status {
  font-size: 1rem;
  font-weight: 500;
  color: #858585;
}

.actions {
  display: flex;
}

.activity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 0.75rem;
  margin-left: auto;
}

.notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--sec-blue);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding: 0.6rem;
}

.notifications > p {
  font-weight: 700;
  font-size: 0.8rem;
  color: #fff;
}

.dots {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  margin: 0 1rem;
  position: relative;
}

.dots:hover > .dot {
  background: var(--sec-blue);
}

.dots:hover .actions-menu {
  visibility: visible;
}

.dots > .dot {
  background: #adadad;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.usermenu {
  margin: 0;
}

.actions-menu {
  flex-direction: column;
  position: absolute;
  z-index: 10;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  left: -7rem;
  top: 1.8rem;
  font-size: 1.2rem;
  visibility: hidden;
  transition: all 0.5s ease;
}

.actions-menu > div {
  padding: 0.5rem;
}

.actions-menu > div:hover {
  color: black;
  cursor: pointer;
}

.goal {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
}
.lesson-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    gap: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius); 
    min-height: 105px;
    margin-right: 10px;
    width: 55%;
}

.materials{
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 30px;
}
.content_link{
    text-decoration: none;
    color: var(--main-blue);
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
}
.content_link:hover{
    text-decoration: none;
    color: var(--main-white);
    cursor: pointer;
    display: flex;
    gap: 5px;
}
.sections-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    gap: 10px;
    padding: 10px;
    margin-left: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    width: 45%;
    min-height: 105px;
}

.sections {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(45px, 1fr));
    width: 100%;
}
  
.section {
    padding: 5px ;
    color: var(--main-blue);
    background-color: var(--main-white);
    border: 1px solid var(--main-blue);
    border-radius: var(--regular-border-radius);
    width: 43px;
    text-align: center;
}

.completed{
  padding: 5px ;
  border: 1px solid var(--main-blue);
  border-radius: var(--regular-border-radius);
  width: 43px;
  text-align: center;
  background-color: var(--main-blue);
  color: #fff;
}
  
.section:hover {
    color: var(--main-white);
    background-color: var(--main-blue);
    border-radius: var(--regular-border-radius);
    cursor: pointer;
}
.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    width: 100%;
  }
  .dataContainer{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
  
  .inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .journal_container{
    display: flex;
    flex-direction: column;
    width: 750px;
    justify-content: center;
  }
  .jorunal_inputs{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  .jorunal_inputs input{
    border-radius: var(--regular-border-radius);
    font-size: 1.5rem;
    padding: 8px;
    width: 200px;
    border: 1px solid var(--main-gray);
  }
  .jorunal_inputs textarea{
    width: 300px;
    border-radius: var(--regular-border-radius);
    border: 1px solid var(--main-gray);
    padding: 1rem;
    overflow-y: scroll;
    resize: none;
    margin-top: 5px;
  
  }
  .material{
    padding: 5px 15px;
    color: var(--main-blue);
    background-color: var(--main-white);
    border: 1px solid var(--main-blue);
    border-radius: var(--regular-border-radius);
    width: fit-content;
  }
  
  .material:hover  {
    color: var(--main-white);
    background-color: var(--main-blue);
    border-radius: var(--regular-border-radius);
    cursor: pointer;
  }

  .material:hover  p{
    color: var(--main-white);
  }
  .material:hover  i{
    color: var(--main-white);
  }
  .sectionDescrip{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .sectionDescrip p{
    font-size: 11px;
  }
  @media (max-width: 750px){
    .goal {
      display: flex;
      justify-content: center;
      margin-top: 0px;
    }
    .lesson-header {
      font-size: 1.4rem;
      gap: 5px;
      padding: 5px;
      min-height: 100%;
      text-align: center;
  }
  .materials{
    gap: 5px;
    align-items: flex-start;
    height: 100%;
    flex-direction: column;
  }
  .material{
    padding: 5px 5px;
  }
  .sections-header {
    font-size: 1.4rem;
    gap: 5px;
    padding: 5px;
    margin-left: 0px;
    min-height: 100%;
  }
  .sectionDescrip p{
    font-size: 10px;
  }
  .sections {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(33px, 1fr));
  }
  .section {
    padding: 3px ;
    width: 33px;
  }

  }
.menu-overlay {
  background-color:  var(--main-blue);
  position: absolute;
  top: -10000px;
  left: -1000px;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all .9s ease ;
}

.menu-overlay-active {
  background-color:  var(--main-blue);
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all .6s ease ;
  border-radius: 0 0 77% 0;
  top: 0;
  left: 0;
  z-index: 3;
  overflow-y: scroll;
  
}
.options {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    height: 100%;
    gap: 1.5rem;
    width: 100%;
  }

.icon {
  font-size: 2.5rem;
  color: var(--main-white)
}
.icon2 {
  font-size: 1.6rem;
  color: var(--main-white)
}
 .title {
    padding: 0 12px;
    font-size: 1.8rem;
    color: var(--main-white);
    text-align: center;
  }
.option{
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.close{
  display: flex;
}
.options {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 10px;
}
.add {
    padding: 8px;
    border-radius: var(--regular-border-radius);
    cursor: pointer;
    background-color: var(--main-blue);
    display: flex;
    align-items: center;
    gap: 7px;
  }
  .add:hover {
    box-shadow: 0 0 7px -1px var(--main-gray);
  }
  .option {
    width: 70px;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
  }
  .option:hover {
    background-color: #DDE1E7;
  }
  .options p {
    font-size: 13px;
  }
  
  .option2 {
    width: 70px;
  display: flex;
  justify-content: center;
  background-color: var(--main-white);
  padding: 10px;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
  box-shadow: 0 0 5px -1px var(--main-gray);
  }
  .sections{
    display: flex;
    gap: 5px;
  }
  .form__fields {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 2rem;
    align-items: center;
  }
  .section_content {
    width: 100%;
  }
  .inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
  }
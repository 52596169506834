.container{
    width: 100%;
}
.header{
    width: 100%;
    justify-content: flex-start;
    display: flex;
}
.header_content{
    width: 30%;
    background-color: var(--background-gray);
    border-radius: var(--regular-border-radius);
    display: flex;
    padding: 5px;
    align-items: center;
    cursor: pointer;
}
.header p{
    font-size: 16px;
    margin-left: 15px;
}
.topics_list{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
    overflow-y: scroll;
    max-height: 78vh;
}
.topic{
    width: 100%;
}
.topic_content{
    display: flex;
    align-items: center;
    width: 100%;
}
.topic_left{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    height: 100%;
}
.left_content{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.topic_left p{
    font-size: 25px;
    color:'#989a9e';
}
.topic_right{
    margin-left: 5px;
    width: 92%;
    margin-right: 1rem;
}
.topic_right h2{
    font-size: 20px;
    margin:0;
}
.topic_right .description {
    font-size: 15px;
    margin-top: 1.5rem;
    line-height: 2rem;
}
.topic_bottom{
    border-top: 1px solid var(--main-gray);
    margin-top: 2rem;
    padding-top: 5px;
    display: flex;
    align-items: center;
}
.topic_bottom .button{
    margin-top: 1rem;
    cursor: pointer;
    font-size: 13px;
    color: var(--ter-blue)
}
.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 20px;
    align-items: flex-start;
  }
.head{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.status_active{
    width: fit-content;
    border-radius: 20px;
    padding: 5px;
    background-color: #2EC401;
    margin-left: auto;
}
.status_closed{
    width: fit-content;
    border-radius: 20px;
    padding: 5px;
    background-color: #F7251D;
    margin-left: auto;
    color: #fff;
}
.unread_messages{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--sec-yellow);
    margin-left: 10px;
}


.container {
  width: 100%;
}
.header_options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;
}
.option {
  cursor: pointer;
  font-size: 14px;
  padding: 1rem 1rem 0.5rem 1rem;
}
.option_selected {
  padding: 1rem 1rem 0.5rem 1rem;
  border-bottom: 2px solid var(--main-blue);
  cursor: pointer;
  font-size: 14px;
}
.option_content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.delete-survey {
  font-size: 1.4rem;
  padding: 5px 10px 5px 10px;
  border: 1px solid var(--sec-red);
  color: var(--sec-red);
  border-radius: var(--regular-border-radius);
}

.delete-survey:hover {
  color: var(--main-white);
  background-color: var(--sec-red);
  cursor: pointer;
}

.form__fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: var(--main-white);
  border-radius: var(--regular-border-radius);
  padding: 20px;
  justify-content: center;
  align-items: center;
}
.search-bar {
  display: flex;
  width: 280px;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  gap: 1rem;
}
.levels {
  max-height: 350px;
  overflow-y: scroll;
}
.levels::-webkit-scrollbar {
  display: none;
}
.filter_text {
  width: 50%;
  height: 24px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
}
.inputs_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
  gap: 10px;
}
.inputs_right p {
  color: black;
  font-size: 1.5rem;
}
.data_table {
  border-radius: var(--regular-border-radius);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.send_button {
  width: 90px;
  padding: 1rem;
  border-radius: var(--regular-border-radius);
  background-color: var(--main-blue);
  border: none;
  color: var(--main-white);
  margin-top: 2rem;
  cursor: pointer;
}
.search-bar {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.filter_text {
  width: 20%;
  height: 30px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
}

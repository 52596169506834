.form__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-color: var(--main-white);
    border-radius: var(--regular-border-radius);
    padding: 20px;
    justify-content: center;
    align-items: center;
}
.create-planilla{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    max-height: 81vh;
}
.inputs {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 2rem;
}
.add_opt{
    display: 'flex';
    flex-direction: column;
}
.inputs_container{
    display: flex;
    flex-direction: column;
}
@media (max-width: 590px){
    .inputs {
        flex-direction: column;
    }
    .create-planilla{
        overflow-x: hidden;
    }

}
/* Código CSS existente */
a {
  text-decoration: none;
  color: inherit;
}

.content {
  display: flex;
  width: 100%;
}

.leftside {
  width: 38%;
  margin-right: 1rem;
  transition: width 0.3s ease; /* Añadir transición suave */
}

.leftside_hidden {
  display: none; /* Ocultar completamente cuando esté oculto */
}

.leftside_content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.left_header_content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 5px 1rem 5px;
  border-bottom: 1px solid var(--ter-blue);
}

.logo-img {
  margin-right: 2rem;
  width: 30%;
}

.logo-img img {
  width: 100%;
}

.leftside_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 1rem 1rem 1rem;
}

.leftside_info i {
  font-size: 19px;
  color: var(--main-blue);
  margin-right: 1rem;
}

.basic_info {
  display: flex;
}

.left_content2 {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_secondary {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left_content2 .title {
  font-size: 24px;
}

.left_content2 .title2 {
  font-size: 18px;
}

.left_content2 .title3 {
  font-size: 16px;
  margin-left: 5px;
  margin-top: 7px;
}

.content_secondary {
  display: flex;
  align-items: center;
}

.leftside h2 {
  font-size: 30px;
  margin: 0;
  font-weight: 500;
}

.leftside p {
  font-size: 14px;
  margin-top: 4px;
  font-weight: 500;
}

.rightside {
  width: 60%;
  transition: width 0.3s ease; /* Añadir transición suave */
}

.fullWidth {
  width: 100%; /* Ancho completo cuando el leftside está oculto */
}

.rightside .options {
  display: flex;
  gap: 1rem;
  background-color: var(--background-gray);
  padding: 10px; 
  border-radius: 10px;
  align-items: center;
}

.rightside .option {
  width: 90px;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
}

.rightside .option:hover {
  background-color: #DDE1E7;
}

.options p {
  font-size: 13px;
}

.rightside .option2 {
  width: 90px;
  display: flex;
  justify-content: center;
  background-color: var(--main-white);
  padding: 10px;
  border-radius: var(--regular-border-radius);
  cursor: pointer;
  box-shadow: 0 0 5px -1px var(--main-gray);
}

.right_content {
  margin-top: 1rem;
  max-height: 84vh;
  width: 100%;
}

.data_table {
  margin: 2rem;
  height: 68vh;
  overflow-y: scroll;
  border-radius: var(--regular-border-radius);
}

.search-bar {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.filter_text {
  width: 35%;
  height: 30px;
  border-radius: var(--regular-border-radius);
  font-size: 1.2rem;
  border: 1px solid var(--main-gray);
  margin: 1rem 2rem 0 0;
}

.content_download {
  background-color: var(--ter-blue);
  width: 100%;
  border-radius: 10px;
  color: #fff;
  padding: 2rem;
  cursor: pointer;
}

/* Nuevo estilo para el botón de alternancia */
.toggleButton {
  position: absolute;
  top: 10px;
  left: 10px; /* Ajusta según tu diseño */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 100; /* Asegúrate de que esté encima del contenido */
}

@media (max-width: 950px) {
  .content {
    display: flex;
    flex-direction: column;
  }
  .leftside {
    width: 100%;
  }
  .rightside {
    width: 100%;
    margin-top: 2rem;
  }
}
.wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 5px;
  flex-direction: column;
}

.leftside {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}
.optionsList{
  background-color: var(--background-gray);
  border-radius: 10px;
  padding: 10px; 
  width: 23%;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.rightside {
  display: flex;
  gap: 10px;
  flex-grow: 1;
  width: 100%;
  margin-right: 5px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  background-color: var(--background-gray);
  border-radius: var(--regular-border-radius);
}

.lesson {
  font-size: 1.6rem;
  padding: 10px;
}

.lesson:hover {
  background-color: var(--light-gray);
  border-radius: var(--regular-border-radius);
  cursor: pointer;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  border-radius: var(--regular-border-radius);
}

.content-header {
  display: flex;
  gap: 10px;
}

.content-header > div {
  font-size: 1.6rem;
  background-color: var(--background-gray);
  padding: 1rem 2rem;
  border-radius: var(--regular-border-radius);
}

.content {
  background-color: var(--background-gray);
}

@media (max-width: 950px){
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .leftside{
    width: 100%;
    flex-direction: column;
  }
  .rightside{
    width: 100%;
    margin-right: 2px;
  }
  .optionsList{
    width: 100%;
  }

}